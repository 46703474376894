import {
    API_URL,
    BACKEND_URL,
    TOKEN_ENDPOINT,
  } from '../server-parameters'
  import { LOCAL_STORAGE_KEYS } from '../constant'
  import { State } from '../state-actions-reducers'
  import { getUserAndFormId } from './useTokenCustomHook'
  
  // const headers = new Headers({
  // 	'content-type': 'application/json',
  // 	accept: 'text/plain'
  // })
  
  export async function searchByInn(state: State, inn: string, fiscalType: string, citizenship: string) {
    const headers = new Headers({
      'content-type': 'application/json',
      accept: 'text/plain',
    })
    if (!headers.has('authorization')) {
      const token = state.formId
      headers.set('Authorization', 'Bearer ' + token)
    }
  
    const fullUrl = BACKEND_URL + '/anketa/checkInn'
  
    const formData = {
      inn, fiscalType: +fiscalType, citizenship
    }
    const fetchOptions = {
      headers,
      body: JSON.stringify(formData),
      method: 'POST',
    }
    let response = await fetch(fullUrl, fetchOptions)
    if (
      response.status === 400 ||
      response.status === 401 ||
      response.status === 403
    ) {
      getUserAndFormId(() => {}, window.localStorage)
    }
    const body = await response.json()
    //console.log(body)
    return body
  }
  