import React from 'react'

import styles from './RedButton.module.scss'

interface Props {
  value: string
  onClick: any
  className: string
  disabled?: boolean
}
export default function RedButton({
  value,
  onClick,
  className,
  disabled,
}: Props) {
  return (
    <button
      onClick={onClick}
      className={styles.RedButton + ' ' + className}
      disabled={disabled}
    >
      {value}
    </button>
  )
}
