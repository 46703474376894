export const getFiscalFullName = (fiscalType: string) => {
  return {
    label: {
      eng: 'Full name*',
      rus: 'Полное наименование*',
      kz: 'Толық аты*',
    },
    placeholder: {
      eng: `${
        fiscalType === '1'
          ? 'IP «London industrial company»'
          : fiscalType === '3'
          ? 'JSC «London industrial company»'
          : fiscalType === '4'
          ? 'PJSC «London industrial company»'
          : fiscalType === '5'
          ? 'CJSC «London industrial company»'
          : fiscalType === '6'
          ? 'LLP «London industrial company»'
          : 'LLC «London industrial company»'
      }`,
      rus: `${
        fiscalType === '1'
          ? 'ИП «Московская промышленная компания»'
          : fiscalType === '3'
          ? 'АО «Московская промышленная компания»'
          : fiscalType === '4'
          ? 'ПАО «Московская промышленная компания»'
          : fiscalType === '5'
          ? 'ЗАО «Московская промышленная компания»'
          : fiscalType === '6'
          ? 'ТОО «Московская промышленная компания»'
          : 'ООО «Московская промышленная компания»'
      }`,
      kz: `${
        fiscalType === '1'
          ? 'IP «Мәскеу өнеркәсіптік компаниясы»'
          : fiscalType === '3'
          ? 'АҚ «Мәскеу өнеркәсіптік компаниясы»'
          : fiscalType === '4'
          ? 'ЖАҚ «Мәскеу өнеркәсіптік компаниясы»'
          : fiscalType === '5'
          ? 'ЖАҚ «Мәскеу өнеркәсіптік компаниясы»'
          : fiscalType === '6'
          ? '(ЖШС «Мәскеу өнеркәсіптік компаниясы»'
          : 'ЖШС «Мәскеу өнеркәсіптік компаниясы»'
      }`,
    },
  }
}
