import { BACKEND_URL } from '../server-parameters'

export async function getCompany(inn: string) {
  const headers = new Headers({
    'content-type': 'application/json',
    accept: 'text/plain',
  })
  if (!headers.has('authorization')) {
    const token = window.localStorage.FORM_ID
    headers.set('Authorization', 'Bearer ' + token)
  }

  const fullUrl = BACKEND_URL + '/anketa/OrganizationByInn?inn=' + inn

  const fetchOptions = {
    headers,
    method: 'POST',
  }
  let response = await fetch(fullUrl, fetchOptions)
  const body = await response.json()
  //console.log(body)
  return body
}
