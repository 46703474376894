import React from 'react'

import styles from './CloseButton.module.scss'

export default function CloseButton(props: { [key: string]: unknown }) {
  const containerClassName = props.className
    ? `${styles.container} ${styles.container}`
    : styles.container
  return (
    <div {...props} className={containerClassName} aria-hidden={true}>
      <div className={styles.crossOne} />
      <div className={styles.crossTwo} />
    </div>
  )
}
