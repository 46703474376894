import { Option } from '../types/common-types'

export const passportTypeKz: Option[] = [
  {
    value: '2',
    label: {
      eng: 'Identification',
      rus: 'Уд. личности',
      kz: 'Жеке куәлік',
    },
  },
]

export const passportTypeRfForKz: Option[] = [
  //   {
  //     value: '3',
  //     label: {
  //       eng: 'International passport',
  //       rus: 'Заграничный паспорт',
  //       kz: 'Шетелдік төлқұжат',
  //     },
  //   },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
  // {
  //   value: '11',
  //   label: {
  //     eng: 'Temp. residence permit',
  //     rus: 'РВП',
  //     kz: 'Уақытша тұруға рұқсат',
  //   },
  // },
  //   {
  //     value: '1',
  //     label: {
  //       eng: 'Internal passport',
  //       rus: 'Внутренний паспорт РФ',
  //       kz: 'Ішкі төлқұжат',
  //     },
  //   },
]
export const passportTypeRf: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Internal passport',
      rus: 'Внутренний паспорт РФ',
      kz: 'Ішкі төлқұжат',
    },
  },
]
export const passportTypeKgz: Option[] = [
  //   {
  //     value: '1',
  //     label: {
  //       eng: 'Biometric passport',
  //       rus: 'Биометрический паспорт',
  //       kz: 'Биометриялық төлқұжат',
  //     },
  //   },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
  // {
  //   value: '11',
  //   label: {
  //     eng: 'Temp. residence permit',
  //     rus: 'РВП',
  //     kz: 'Уақытша тұруға рұқсат',
  //   },
  // },
  //   {
  //     value: '8',
  //     label: {
  //       eng: 'ID card',
  //       rus: 'ID карта',
  //       kz: 'Жеке куәлік',
  //     },
  //   },
]

export const passportTypeRom: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Simple electronic passport',
      rus: 'Простой электронный паспорт',
      kz: 'Қарапайым электрондық төлқұжат',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypeGer: Option[] = [
  {
    value: '3',
    label: {
      eng: 'International passport',
      rus: 'Заграничный паспорт',
      kz: 'Халықаралық паспорт',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypeUsa: Option[] = [
  {
    value: '1',
    label: {
      eng: 'United States Passport',
      rus: 'Паспорт гражданина США',
      kz: 'АҚШ төлқұжаты ',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypeTaj: Option[] = [
  {
    value: '3',
    label: {
      eng: 'International passport',
      rus: 'Заграничный паспорт',
      kz: 'Халықаралық паспорт',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypeTur: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Turkish Passport',
      rus: 'Турецкий паспорт',
      kz: 'Түрік төлқұжаты',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const passportTypeUzb: Option[] = [
  {
    value: '3',
    label: {
      eng: 'International passport',
      rus: 'Заграничный паспорт',
      kz: 'Халықаралық паспорт',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]

export const DocumentTypeValuesUdvAbroad: Option[] = [
  {
    value: '3',
    label: {
      eng: 'International passport',
      rus: 'Заграничный паспорт',
      kz: 'Халықаралық паспорт',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Residence permit',
      rus: 'Вид на жительство',
      kz: 'Тұруға ықтиярхат',
    },
  },
]
export const DocumentTypeValuesTypePersonalities: Option[] = [
  {
    value: '2',
    label: {
      eng: 'Oud. personalities',
      rus: 'Уд. личности',
      kz: 'Оуд. тұлғалар',
    },
  },
]
export const DocumentTypeValuesTypePassport: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Passport',
      rus: 'Паспорт',
      kz: 'Паспорт',
    },
  },
]
