import { RegionCity } from '../types/common-types'

const KzRegionCityList: RegionCity[] = [
  {
    region: 'Северо-Казахстанская область',
    city: 'Боголюбово',
  },
  {
    region: 'Северо-Казахстанская область',
    city: 'Булаево',
  },
  {
    region: 'Северо-Казахстанская область',
    city: 'Дубровное',
  },
  {
    region: 'Северо-Казахстанская область',
    city: 'Кабан',
  },
  {
    region: 'Северо-Казахстанская область',
    city: 'Кара-Куга',
  },
  {
    region: 'Северо-Казахстанская область',
    city: 'Мамлютка',
  },
  {
    region: 'Северо-Казахстанская область',
    city: 'Налобино',
  },
  {
    region: 'Северо-Казахстанская область',
    city: 'Новоникольское',
  },
  {
    region: 'Северо-Казахстанская область',
    city: 'Новопокровка',
  },
  {
    region: 'Северо-Казахстанская область',
    city: 'Петропавловск',
  },
  {
    region: 'Северо-Казахстанская область',
    city: 'Полудино',
  },
  {
    region: 'Северо-Казахстанская область',
    city: 'Пресновка',
  },
  {
    region: 'Северо-Казахстанская область',
    city: 'Соколовка',
  },
  {
    region: 'Северо-Казахстанская область',
    city: 'Екатриновка',
  },
  {
    region: 'Северо-Казахстанская область',
    city: 'Кызылагаш',
  },
  {
    region: 'Акмолинская область',
    city: 'Астана',
  },
  {
    region: 'Акмолинская область',
    city: 'Атбасар',
  },
  {
    region: 'Акмолинская область',
    city: 'Новорыбинка',
  },
  {
    region: 'Акмолинская область',
    city: 'Кокчетав',
  },
  {
    region: 'Акмолинская область',
    city: 'Есиль',
  },
  {
    region: 'Акмолинская область',
    city: 'Аккуль',
  },
  {
    region: 'Акмолинская область',
    city: 'Молодёжное',
  },
  {
    region: 'Акмолинская область',
    city: 'Кызылтан',
  },
  {
    region: 'Акмолинская область',
    city: 'Алексеевка',
  },
  {
    region: 'Акмолинская область',
    city: 'Озен (Раздольное)',
  },
  {
    region: 'Акмолинская область',
    city: 'Азат',
  },
  {
    region: 'Акмолинская область',
    city: 'Оркен (Куропаткино)',
  },
  {
    region: 'Акмолинская область',
    city: 'Теректи (Линеевка)',
  },
  {
    region: 'Акмолинская область',
    city: 'Уялы (Чаглинский С.О)',
  },
  {
    region: 'Акмолинская область',
    city: 'Кулет',
  },
  {
    region: 'Акмолинская область',
    city: 'Жамантуз',
  },
  {
    region: 'Акмолинская область',
    city: 'Щучинск',
  },
  {
    region: 'Акмолинская область',
    city: 'Акылбай',
  },
  {
    region: 'Акмолинская область',
    city: 'Ортак',
  },
  {
    region: 'Акмолинская область',
    city: 'Ток',
  },
  {
    region: 'Акмолинская область',
    city: 'Бидайык (Бидаик)',
  },
  {
    region: 'Акмолинская область',
    city: 'Каскат',
  },
  {
    region: 'Акмолинская область',
    city: 'Жаналык (Акмола обл)',
  },
  {
    region: 'Акмолинская область',
    city: 'Дальний',
  },
  {
    region: 'Акмолинская область',
    city: 'Бауркарагай',
  },
  {
    region: 'Акмолинская область',
    city: 'Альбек',
  },
  {
    region: 'Актюбинская область',
    city: 'Акшимрау',
  },
  {
    region: 'Актюбинская область',
    city: 'Бейнеу',
  },
  {
    region: 'Актюбинская область',
    city: 'Аккабак',
  },
  {
    region: 'Актюбинская область',
    city: 'Актобе',
  },
  {
    region: 'Актюбинская область',
    city: 'Алга',
  },
  {
    region: 'Актюбинская область',
    city: 'Берчогур',
  },
  {
    region: 'Актюбинская область',
    city: 'Бестамак',
  },
  {
    region: 'Актюбинская область',
    city: 'Улпан',
  },
  {
    region: 'Актюбинская область',
    city: 'Шошкаколь',
  },
  {
    region: 'Актюбинская область',
    city: 'Иргиз',
  },
  {
    region: 'Актюбинская область',
    city: 'Калиновка',
  },
  {
    region: 'Актюбинская область',
    city: 'Каратогай',
  },
  {
    region: 'Актюбинская область',
    city: 'Караулкельды',
  },
  {
    region: 'Актюбинская область',
    city: 'Казацкий',
  },
  {
    region: 'Актюбинская область',
    city: 'Хромтау',
  },
  {
    region: 'Актюбинская область',
    city: 'Кулакши',
  },
  {
    region: 'Актюбинская область',
    city: 'Ногайты',
  },
  {
    region: 'Актюбинская область',
    city: 'Новороссийский',
  },
  {
    region: 'Актюбинская область',
    city: 'Огородный',
  },
  {
    region: 'Актюбинская область',
    city: 'Сарыоба',
  },
  {
    region: 'Актюбинская область',
    city: 'Скол',
  },
  {
    region: 'Актюбинская область',
    city: 'Сокырбулак',
  },
  {
    region: 'Актюбинская область',
    city: 'Карабутак',
  },
  {
    region: 'Актюбинская область',
    city: 'Курмансай (Степановка)',
  },
  {
    region: 'Актюбинская область',
    city: 'Темир',
  },
  {
    region: 'Актюбинская область',
    city: 'Тогыз',
  },
  {
    region: 'Актюбинская область',
    city: 'Ойыл',
  },
  {
    region: 'Актюбинская область',
    city: 'Заря Октября',
  },
  {
    region: 'Актюбинская область',
    city: 'Жаркамыс',
  },
  {
    region: 'Актюбинская область',
    city: 'Шалкар',
  },
  {
    region: 'Актюбинская область',
    city: 'Маржанбулак (Прогресс)',
  },
  {
    region: 'Алматинская область',
    city: 'Алматы',
  },
  {
    region: 'Алматинская область',
    city: 'Баканас',
  },
  {
    region: 'Алматинская область',
    city: 'Бирлик',
  },
  {
    region: 'Алматинская область',
    city: 'Шалкыдысу',
  },
  {
    region: 'Алматинская область',
    city: 'Токжайлау',
  },
  {
    region: 'Алматинская область',
    city: 'Конаев',
  },
  {
    region: 'Алматинская область',
    city: 'Караой',
  },
  {
    region: 'Алматинская область',
    city: 'Коксу',
  },
  {
    region: 'Алматинская область',
    city: 'Коктума',
  },
  {
    region: 'Алматинская область',
    city: 'Куйган',
  },
  {
    region: 'Алматинская область',
    city: 'Лепсы',
  },
  {
    region: 'Алматинская область',
    city: 'Матай',
  },
  {
    region: 'Алматинская область',
    city: 'Жаркент',
  },
  {
    region: 'Алматинская область',
    city: 'Сарканд',
  },
  {
    region: 'Алматинская область',
    city: 'Сарыозек',
  },
  {
    region: 'Алматинская область',
    city: 'Талды-Курган',
  },
  {
    region: 'Алматинская область',
    city: 'Тегермен',
  },
  {
    region: 'Алматинская область',
    city: 'Текели',
  },
  {
    region: 'Алматинская область',
    city: 'Уштобе',
  },
  {
    region: 'Алматинская область',
    city: 'Талгар',
  },
  {
    region: 'Алматинская область',
    city: 'Томар',
  },
  {
    region: 'Алматинская область',
    city: 'Нарынкол',
  },
  {
    region: 'Алматинская область',
    city: 'Чунджа',
  },
  {
    region: 'Алматинская область',
    city: 'Кеген',
  },
  {
    region: 'Алматинская область',
    city: 'Жаркент',
  },
  {
    region: 'Алматинская область',
    city: 'Есик',
  },
  {
    region: 'Алматинская область',
    city: 'Костобе',
  },
  {
    region: 'Алматинская область',
    city: 'Бояулы',
  },
  {
    region: 'Алматинская область',
    city: 'Барибаева',
  },
  {
    region: 'Алматинская область',
    city: 'Акколь (Алматинская обл)',
  },
  {
    region: 'Алматинская область',
    city: 'Бакбакты',
  },

  {
    region: 'Атырауская область',
    city: 'Атырау',
  },
  {
    region: 'Атырауская область',
    city: 'Айбас',
  },
  {
    region: 'Атырауская область',
    city: 'Байшунас',
  },
  {
    region: 'Атырауская область',
    city: 'Доссор',
  },
  {
    region: 'Атырауская область',
    city: 'Индербор',
  },
  {
    region: 'Атырауская область',
    city: 'Каратон',
  },
  {
    region: 'Атырауская область',
    city: 'Комсомольский',
  },
  {
    region: 'Атырауская область',
    city: 'Кульсары',
  },
  {
    region: 'Атырауская область',
    city: 'Макат',
  },
  {
    region: 'Атырауская область',
    city: 'Миялы',
  },
  {
    region: 'Атырауская область',
    city: 'Мукур',
  },
  {
    region: 'Атырауская область',
    city: 'Мунайлы',
  },
  {
    region: 'Атырауская область',
    city: 'Прорва',
  },
  {
    region: 'Атырауская область',
    city: 'Сарайшык',
  },
  {
    region: 'Атырауская область',
    city: 'Забурунье',
  },
  {
    region: 'Атырауская область',
    city: 'Жамансор',
  },
  {
    region: 'Атырауская область',
    city: 'Жангалы',
  },
  {
    region: 'Атырауская область',
    city: 'Акиз',
  },
  {
    region: 'Атырауская область',
    city: 'Аккизтогай',
  },
  {
    region: 'Атырауская область',
    city: 'Аккистау',
  },
  {
    region: 'Атырауская область',
    city: 'Атекб',
  },
  {
    region: 'Атырауская область',
    city: 'Айртам',
  },
  {
    region: 'Атырауская область',
    city: 'Азгир',
  },
  {
    region: 'Атырауская область',
    city: 'Балкудук',
  },
  {
    region: 'Атырауская область',
    city: 'Бирлик',
  },
  {
    region: 'Атырауская область',
    city: 'Дамба',
  },
  {
    region: 'Атырауская область',
    city: 'Камынин',
  },
  {
    region: 'Атырауская область',
    city: 'Карасыр',
  },
  {
    region: 'Атырауская область',
    city: 'Казбек',
  },
  {
    region: 'Атырауская область',
    city: 'Косчагыл',
  },
  {
    region: 'Атырауская область',
    city: 'Махамбет',
  },
  {
    region: 'Атырауская область',
    city: 'Новобогатинское',
  },
  {
    region: 'Атырауская область',
    city: 'Новый Уштаган',
  },
  {
    region: 'Атырауская область',
    city: 'Орпа',
  },
  {
    region: 'Атырауская область',
    city: 'Пешной',
  },
  {
    region: 'Атырауская область',
    city: 'Сагиз',
  },
  {
    region: 'Атырауская область',
    city: 'Шокпартогай',
  },
  {
    region: 'Атырауская область',
    city: 'Тандау',
  },
  {
    region: 'Атырауская область',
    city: 'Тас',
  },
  {
    region: 'Атырауская область',
    city: 'Толеген',
  },
  {
    region: 'Атырауская область',
    city: 'Жанбай',
  },
  {
    region: 'Атырауская область',
    city: 'Жалгызапан',
  },
  {
    region: 'Атырауская область',
    city: 'Жаскайрат',
  },
  {
    region: 'Атырауская область',
    city: 'Курмангазы',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Аксуат',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Акжал',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Аягоз',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Бахты',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Белагаш',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Боко',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Чердояк',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Чубартау',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Доненбай',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Карааул ',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Карабулак',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Карагужиха ',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Каратан',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Кызылогиз',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Лениногорск',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Рахмановское',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Семей',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Шемонаиха',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Социал',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Таскескен',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Урджар',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Урыль',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Усть-Каменогорск',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Зайсан',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Жарма',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Знаменка',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Зыряновск',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Сергеевка',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Прапорщиково',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Украинка',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Уварово',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Глубокое',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Белоусовка',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Бобровка (Глубоковский район)',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Винное',
  },
  {
    region: 'Восточно-Казахстанская область',
    city: 'Секисовка',
  },
  {
    region: 'Жамбылская область',
    city: 'Акжар',
  },
  {
    region: 'Жамбылская область',
    city: 'Алгатарт',
  },
  {
    region: 'Жамбылская область',
    city: 'Чу',
  },
  {
    region: 'Жамбылская область',
    city: 'Тараз',
  },
  {
    region: 'Жамбылская область',
    city: 'Мойынкум',
  },
  {
    region: 'Жамбылская область',
    city: 'Камкалы',
  },
  {
    region: 'Жамбылская область',
    city: 'Каратау ',
  },
  {
    region: 'Жамбылская область',
    city: 'Мынарал',
  },
  {
    region: 'Жамбылская область',
    city: 'Отар',
  },
  {
    region: 'Жамбылская область',
    city: 'Уюк',
  },
  {
    region: 'Жамбылская область',
    city: 'Жанатас',
  },
  {
    region: 'Жамбылская область',
    city: 'Мерке',
  },
  {
    region: 'Жамбылская область',
    city: 'Чалдовар ',
  },
  {
    region: 'Жамбылская область',
    city: 'Жамбул (Мерке р.н)',
  },
  {
    region: 'Жамбылская область',
    city: 'Интернациональное',
  },
  {
    region: 'Жамбылская область',
    city: 'Ойтал',
  },
  {
    region: 'Жамбылская область',
    city: 'Татти',
  },
  {
    region: 'Жамбылская область',
    city: 'Казах',
  },
  {
    region: 'Жамбылская область',
    city: 'Луговой',
  },
  {
    region: 'Жамбылская область',
    city: 'Кулан',
  },
  {
    region: 'Жамбылская область',
    city: 'Когершин',
  },
  {
    region: 'Жамбылская область',
    city: 'Акыртобе',
  },
  {
    region: 'Жамбылская область',
    city: 'Орнек (Рыскулов р.н)',
  },

  {
    region: 'Западно-Казахстанская область',
    city: 'Аксу',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Кайынды',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Битик',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Болашак (Казталов)',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Богет',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Бостандык',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Булдырты',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Чапаев',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Чижа-2',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Джамбейты ',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Сарыколь',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Жалпактал',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Тайпак',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Казталовка ',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Акшат',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Мастексай',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Мергенево ',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Жанаказан',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Уральск',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Ашысай',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Сергей Аула',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Шалкар',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Аккурай',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Тау',
  },
  {
    region: 'Западно-Казахстанская область',
    city: 'Жетыбай',
  },

  {
    region: 'Карагандинская область',
    city: 'Абай',
  },
  {
    region: 'Карагандинская область',
    city: 'Агадырь',
  },
  {
    region: 'Карагандинская область',
    city: 'Актас',
  },
  {
    region: 'Карагандинская область',
    city: 'Актогай',
  },
  {
    region: 'Карагандинская область',
    city: 'Актума',
  },
  {
    region: 'Карагандинская область',
    city: 'Айшырак',
  },
  {
    region: 'Карагандинская область',
    city: 'Балхаш',
  },
  {
    region: 'Карагандинская область',
    city: 'Джезказган',
  },
  {
    region: 'Карагандинская область',
    city: 'Караганда',
  },
  {
    region: 'Карагандинская область',
    city: 'Каражал',
  },
  {
    region: 'Карагандинская область',
    city: 'Каражынгыл',
  },
  {
    region: 'Карагандинская область',
    city: 'Каркаралинск',
  },
  {
    region: 'Карагандинская область',
    city: 'Карсакбай',
  },
  {
    region: 'Карагандинская область',
    city: 'Кенсе',
  },
  {
    region: 'Карагандинская область',
    city: 'Киевка',
  },
  {
    region: 'Карагандинская область',
    city: 'Кургасын',
  },
  {
    region: 'Карагандинская область',
    city: 'Кызылдикан',
  },
  {
    region: 'Карагандинская область',
    city: 'Кызыл-Джар',
  },
  {
    region: 'Карагандинская область',
    city: 'Кызыл коммуна',
  },
  {
    region: 'Карагандинская область',
    city: 'Кызылтау',
  },
  {
    region: 'Карагандинская область',
    city: 'Кызылуй',
  },
  {
    region: 'Карагандинская область',
    city: 'Моинты',
  },
  {
    region: 'Карагандинская область',
    city: 'Сатпаев',
  },
  {
    region: 'Карагандинская область',
    city: 'Нура',
  },
  {
    region: 'Карагандинская область',
    city: 'Сарань',
  },
  {
    region: 'Карагандинская область',
    city: 'Сары-Шаган',
  },
  {
    region: 'Карагандинская область',
    city: 'Саяк',
  },
  {
    region: 'Карагандинская область',
    city: 'Шахтинск ',
  },
  {
    region: 'Карагандинская область',
    city: 'Шалгия',
  },
  {
    region: 'Карагандинская область',
    city: 'Соналы',
  },
  {
    region: 'Карагандинская область',
    city: 'Соролен',
  },
  {
    region: 'Карагандинская область',
    city: 'Тан',
  },
  {
    region: 'Карагандинская область',
    city: 'Тасарал',
  },
  {
    region: 'Карагандинская область',
    city: 'Темиртау',
  },
  {
    region: 'Карагандинская область',
    city: 'Торабай',
  },
  {
    region: 'Карагандинская область',
    city: 'Успенский',
  },
  {
    region: 'Карагандинская область',
    city: 'Егиндыбулак',
  },
  {
    region: 'Карагандинская область',
    city: 'Жантели',
  },
  {
    region: 'Карагандинская область',
    city: 'Шахан',
  },
  {
    region: 'Карагандинская область',
    city: 'Каражар',
  },

  {
    region: 'Костанайская область',
    city: 'Тамкамыс',
  },
  {
    region: 'Костанайская область',
    city: 'Тургай',
  },
  {
    region: 'Костанайская область',
    city: 'Акшы',
  },
  {
    region: 'Костанайская область',
    city: 'Амангельды',
  },
  {
    region: 'Костанайская область',
    city: 'Аркалык',
  },
  {
    region: 'Костанайская область',
    city: 'Бирали',
  },
  {
    region: 'Костанайская область',
    city: 'Джетыгара',
  },
  {
    region: 'Костанайская область',
    city: 'Кокалат',
  },
  {
    region: 'Костанайская область',
    city: 'Костанай',
  },
  {
    region: 'Костанайская область',
    city: 'Кызылжар ',
  },
  {
    region: 'Костанайская область',
    city: 'Пресногорьковка',
  },
  {
    region: 'Костанайская область',
    city: 'Сага',
  },
  {
    region: 'Костанайская область',
    city: 'Шенбер',
  },
  {
    region: 'Костанайская область',
    city: 'Фрунзенское',
  },
  {
    region: 'Костанайская область',
    city: 'Рудный',
  },
  {
    region: 'Костанайская область',
    city: 'Аятское',
  },
  {
    region: 'Кызылординская область',
    city: 'Узынкайыр',
  },
  {
    region: 'Кызылординская область',
    city: 'Байконур',
  },
  {
    region: 'Кызылординская область',
    city: 'Аккеспе    ',
  },
  {
    region: 'Кызылординская область',
    city: 'Аральск',
  },
  {
    region: 'Кызылординская область',
    city: 'Жаксыкылыш',
  },
  {
    region: 'Кызылординская область',
    city: 'Ауан',
  },
  {
    region: 'Кызылординская область',
    city: 'Айдарлы',
  },
  {
    region: 'Кызылординская область',
    city: 'Байгекум',
  },
  {
    region: 'Кызылординская область',
    city: 'Чиили',
  },
  {
    region: 'Кызылординская область',
    city: 'Диермен тобе',
  },
  {
    region: 'Кызылординская область',
    city: 'Джусалы',
  },
  {
    region: 'Кызылординская область',
    city: 'Еримбет',
  },
  {
    region: 'Кызылординская область',
    city: 'Каскакулан',
  },
  {
    region: 'Кызылординская область',
    city: 'Казалинск',
  },
  {
    region: 'Кызылординская область',
    city: 'Куланды',
  },
  {
    region: 'Кызылординская область',
    city: 'Кызылорда',
  },
  {
    region: 'Кызылординская область',
    city: 'Айтеке-Би',
  },
  {
    region: 'Кызылординская область',
    city: 'Саксаульский',
  },
  {
    region: 'Кызылординская область',
    city: 'Сулутобе',
  },
  {
    region: 'Кызылординская область',
    city: 'Терен-Озек',
  },
  {
    region: 'Кызылординская область',
    city: 'Жана-Корган',
  },
  {
    region: 'Кызылординская область',
    city: 'Айдарлы',
  },
  {
    region: 'Кызылординская область',
    city: 'Бесарык',
  },
  {
    region: 'Кызылординская область',
    city: 'Талап',
  },

  {
    region: 'Мангистауская область',
    city: 'Форт-Шевченко',
  },
  {
    region: 'Мангистауская область',
    city: 'Культау',
  },
  {
    region: 'Мангистауская область',
    city: 'Куюше',
  },
  {
    region: 'Мангистауская область',
    city: 'Мангышлак',
  },
  {
    region: 'Мангистауская область',
    city: 'Жанаозен',
  },
  {
    region: 'Мангистауская область',
    city: 'Боранкул (Ст. Опорная)',
  },
  {
    region: 'Мангистауская область',
    city: 'Саура',
  },
  {
    region: 'Мангистауская область',
    city: 'Сай Отес',
  },
  {
    region: 'Мангистауская область',
    city: 'Саина Шапагатова',
  },
  {
    region: 'Мангистауская область',
    city: 'Шетпе',
  },
  {
    region: 'Мангистауская область',
    city: 'Актау',
  },
  {
    region: 'Мангистауская область',
    city: 'Таучик',
  },
  {
    region: 'Мангистауская область',
    city: 'Уштаган',
  },
  {
    region: 'Мангистауская область',
    city: 'Имени Калинина',
  },
  {
    region: 'Мангистауская область',
    city: 'Баутино',
  },
  {
    region: 'Мангистауская область',
    city: 'Кызык',
  },
  {
    region: 'Мангистауская область',
    city: 'Курык',
  },
  {
    region: 'Мангистауская область',
    city: 'Ералиева',
  },
  {
    region: 'Мангистауская область',
    city: 'Жынгылды (Куйбышево)',
  },
  {
    region: 'Павлодарская область',
    city: 'Алексеевка',
  },
  {
    region: 'Павлодарская область',
    city: 'Павлодар',
  },
  {
    region: 'Павлодарская область',
    city: 'Экибастуз',
  },

  {
    region: 'Туркестанская область',
    city: 'Аксумбе ',
  },
  {
    region: 'Туркестанская область',
    city: 'Арысь',
  },
  {
    region: 'Туркестанская область',
    city: 'Bagara',
  },
  {
    region: 'Туркестанская область',
    city: 'Баиркум',
  },
  {
    region: 'Туркестанская область',
    city: 'Байжансай',
  },
  {
    region: 'Туркестанская область',
    city: 'Чардара',
  },
  {
    region: 'Туркестанская область',
    city: 'Шымкент (Чимкент)',
  },
  {
    region: 'Туркестанская область',
    city: 'Чулаккурган',
  },
  {
    region: 'Туркестанская область',
    city: 'Фоголево',
  },
  {
    region: 'Туркестанская область',
    city: 'Кентау',
  },
  {
    region: 'Туркестанская область',
    city: 'Коксарай',
  },
  {
    region: 'Туркестанская область',
    city: 'Сарыагаш',
  },
  {
    region: 'Туркестанская область',
    city: 'Сузак',
  },
  {
    region: 'Туркестанская область',
    city: 'Тасты',
  },
  {
    region: 'Туркестанская область',
    city: 'Туркестан',
  },
  {
    region: 'Туркестанская область',
    city: 'Жетысай',
  },
  {
    region: 'Туркестанская область',
    city: 'Отрар',
  },
  {
    region: 'Туркестанская область',
    city: 'Карашик',
  },
  {
    region: 'Туркестанская область',
    city: 'Кушата',
  },
  {
    region: 'Туркестанская область',
    city: 'Баялдыр',
  },
  {
    region: 'Туркестанская область',
    city: 'Бостандык (Урангай С.О)',
  },
  {
    region: 'Туркестанская область',
    city: 'Урангай',
  },
  {
    region: 'Туркестанская область',
    city: 'Шипан',
  },
  {
    region: 'Туркестанская область',
    city: 'Коммуна',
  },
  {
    region: 'Туркестанская область',
    city: 'Кызылжол',
  },
  {
    region: 'Туркестанская область',
    city: 'Карнак',
  },
  {
    region: 'Туркестанская область',
    city: 'Шаштобе',
  },
  {
    region: 'Туркестанская область',
    city: 'Кумайлыкас',
  },
  {
    region: 'Туркестанская область',
    city: 'Серт',
  },
  {
    region: 'Туркестанская область',
    city: 'Барисовка',
  },
  {
    region: 'Туркестанская область',
    city: 'Шубанак',
  },
  {
    region: 'Туркестанская область',
    city: 'Староикан',
  },
  {
    region: 'Туркестанская область',
    city: 'Иттифак',
  },
  {
    region: 'Туркестанская область',
    city: 'Теке',
  },
  {
    region: 'Туркестанская область',
    city: '30 лет Казахстана',
  },
  {
    region: 'Туркестанская область',
    city: 'Ибата',
  },
  {
    region: 'Туркестанская область',
    city: 'Сауран',
  },
  {
    region: 'Туркестанская область',
    city: 'Жана Сауран',
  },
  {
    region: 'Туркестанская область',
    city: 'Бабайкурган',
  },
  {
    region: 'Туркестанская область',
    city: 'Ленгер',
  },
]
export default KzRegionCityList
