import React, { useMemo, useEffect, useState, useRef, Key } from 'react'
import { getProducts } from '../../hooks/useProductsCustomHook'
import { generateId } from '../../utils/helpers'
import TextInput from '../Inputs/TextInput'
import DeleteButton from '../Buttons/DeleteButton'
import styles from './ProductsInput.module.scss'
import AccountForm from '../../types/account-form.types'
import { State, actions, Dispatch } from '../../state-actions-reducers'
import BackButtonImg from '../../images/arrow-left.svg'
import CheckProductIcon from '../../images/checkProductIcon.svg'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

// TODO: set up fonts. Current is too big ang probably is from wrong family

interface Props {
  lang: Lang
  onChange: (value: string[]) => void
  initialValue: string[]
  accountForm: AccountForm
  valid: boolean
  required?: boolean
  state: State
  dispatch: Dispatch
}

export default function ProductsInput({
  state,
  lang,
  accountForm,
  onChange,
  initialValue,
  valid,
  required,
  dispatch,
}: Props) {
  const searchInput = useRef(null)
  const id = useMemo(generateId, [])
  const [inputValues, setinputValues] = useState<any[]>(state.goods)

  const inputItem = inputValues.map((item, index) => {
    return (
      <li key={index}>
        <span>{item.title}</span>
        <DeleteButton
          onClick={() => {
            setinputValues(inputValues.filter((item, key) => key !== index))
          }}
        />
      </li>
    )
  })

  const [categories, setCategories]: any = useState([])
  const [products, setProducts]: any = useState([])

  useMemo(() => {
    getProducts(body => {
      if (body?.categories[0]?.nodes?.length > 0)
        setCategories(body.categories[0].nodes.find((n:any)=> n.name === 'Аренда').nodes)
      setProducts(body.catalogItems)
    })
  }, [])

  const [activeCategory, setActiveCategory] = useState()
  const [activeCategoryTitle, setActiveCategoryTitle] = useState()
  const [activeSubCategory, setActiveSubCategory] = useState(false)
  const [activeSubCategoryTitle, setActiveSubCategoryTitle] = useState()
  const getCategoriesTree = (categories: Array<Object>) => {
    return categories?.map((category: any, index: number) => {
      return (
        <li
          key={index}
          className={activeCategory === category.guid ? styles.active : ''}
          onClick={() => {
            setActiveCategory(category.guid)
            setActiveCategoryTitle(category.name)
            setActiveSubCategory(false)
          }}
        >
          <strong>{category.name}</strong>
        </li>
      )
    })
  }
  const getSubCategoriesTree = (categories: Array<Object>) => {
    return categories?.map((category: any) => {
      if (category.nodes && activeCategory === category.guid)
        return category.nodes.map((subCategory: any, index: number) => {
          return (
            <li
              key={index}
              className={
                activeSubCategory === subCategory.guid ? styles.active : ''
              }
              onClick={() => {
                setActiveSubCategory(subCategory.guid)
                setActiveSubCategoryTitle(subCategory.name)
              }}
            >
              <strong>{subCategory.name}</strong>
            </li>
          )
        })
    })
  }
  const findCategoryNodes = (id: any, categories: any) => {
    let nodeResult = false
    categories.forEach((categoryNode: any, index: number) => {
      categoryNode.guid === id
        ? (nodeResult = categoryNode.guid === id)
        : categoryNode.nodes
        ? findCategoryNodes(id, categoryNode.nodes)
        : index + 1 === categories.length
        ? false
        : false
    })
    return nodeResult
  }
  const findProductsByCategories = (id: any, categories: any) => {
    let result = false
    categories.forEach((category: any) => {
      if (category?.nodes?.length > 0)
        category.nodes.forEach((item: any) => {
          if (item.guid === activeSubCategory)
            item.guid === id
              ? (result = item.guid === id)
              : item.nodes
              ? (result = findCategoryNodes(id, item.nodes))
              : false
        })
    })
    return result
  }

  const categoriesTree = useMemo(() => {
    return getCategoriesTree(categories)
  }, [categories, activeCategory])
  const categoriesSubTree = useMemo(() => {
    return getSubCategoriesTree(categories)
  }, [categories, activeCategory, activeSubCategory])
  const productsList = useMemo(() => {
    return products.map((product: any, index: number) => {
      return product?.categoryGuids?.map((parentGuid: string) => {
        if (
          (activeCategory &&
            parentGuid === activeCategory &&
            !activeSubCategory) ||
          (activeSubCategory &&
            findProductsByCategories(parentGuid, categories))
        ) {
          return (
            <li
              key={parentGuid + product.guid}
              onClick={() => {
                console.log('length', inputValues.length)
                if (inputValues.length < 10) {
                  console.log('inputValues', inputValues)
                  setinputValues(inputValues => [
                    ...inputValues,
                    { title: product.title, guid: product.guid },
                  ])
                }
              }}
            >
              <strong>{product.title}</strong>
              {product.inStock ? (
                <>
                  <div className={styles.tooltip}>
                    <img src={CheckProductIcon} alt="CheckProductIcon" />
                    <span>{accountForm?.checkProductMessage[lang]}</span>
                  </div>
                </>
              ) : null}
            </li>
          )
        }
      })
    })
  }, [products, activeCategory, activeSubCategory, inputValues])

  const defaultValue = useMemo(
    () =>
      initialValue.map(option => ({
        value: option,
        label: option,
      })),
    [initialValue]
  )

  useEffect(() => {
    return onChange(inputValues)
  }, [inputValues])

  const [mobileTree, setMobileTree] = useState(<></>)

  useEffect(() => {
    !activeCategory
      ? setMobileTree(<ul>{categoriesTree}</ul>)
      : !activeSubCategory
      ? setMobileTree(<ul>{categoriesSubTree}</ul>)
      : setMobileTree(<ul className={styles.productsUl}>{productsList}</ul>)
  }, [activeCategory, activeSubCategory, categoriesTree])
  function backButtonMobile() {
    activeSubCategory
      ? (setActiveSubCategory(false), setActiveSubCategoryTitle(undefined))
      : (setActiveCategory(undefined), setActiveCategoryTitle(undefined))
  }
  return (
    <div className="row">
      <div className="col-12 col-xs-12">
        <div className={styles.productsContainer}>
          <div className={styles.productsContainerRow}>
            <div className="col-4 col-xs-12">
              <ul>{categoriesTree}</ul>
            </div>
            <div className="col-4 col-xs-12">
              <ul>{categoriesSubTree}</ul>
            </div>
            <div className="col-4 col-xs-12">
              <ul className={styles.productsUl}>{productsList}</ul>
            </div>
          </div>
        </div>
        <div className={styles.productsContainerMobile}>
          <div className={styles.productsContainerMobileRow}>
            {activeCategoryTitle || activeSubCategoryTitle ? (
              <div
                className={
                  styles.productsContainerMobileBackButton + ' ' + 'col-12'
                }
              >
                <img
                  src={BackButtonImg}
                  alt="back"
                  onClick={backButtonMobile}
                />
                <b>
                  {activeSubCategoryTitle
                    ? activeSubCategoryTitle
                    : activeCategoryTitle}
                </b>
              </div>
            ) : null}
            <div className={styles.productsMobileBox + ' ' + 'col-12'}>
              {mobileTree}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-xs-12">
        <p className={styles.labelText}>
          {accountForm.productsInputText.label[lang]}
          {required && <span className={styles.required}></span>}
        </p>
        <div className={styles.inputContainer}>
          <ul>{inputItem}</ul>
        </div>
      </div>
      <div className="col-6 col-xs-12">
        <label htmlFor="" className={styles.searchInputBox}>
          <p>{accountForm.otherProducts[lang]}</p>
          <input
            ref={searchInput}
            type="text"
            onKeyPress={event => {
              let value = event.target.value
              if (event.charCode === 13) {
                if (inputValues.length < 10) {
                  setinputValues(inputValues => [
                    ...inputValues,
                    { title: value, guid: '' },
                  ])
                  event.target.value = ''
                }
              }
            }}
          />
        </label>
      </div>
      <div className="col-6 col-xs-12">
        <TextInput
          testName="feedback"
          testErrorName="error-feedback"
          disabled={!state.agreed}
          lang={lang}
          content={accountForm.feedback}
          onChange={feedback =>
            dispatch({
              type: actions.FEEDBACK,
              data: feedback,
            })
          }
          value={state.feedback || ''}
          valid={true}
        />
      </div>
    </div>
  )
}
