import React from 'react'

import { Lang } from '../types/common-types'
import AccountForm from '../types/account-form.types'
import styles from './ErrorScreen.module.scss'

interface Props {
  accountForm: AccountForm
  lang: Lang
  onError: () => void
  onAccept: () => void
}

const ErrorScreen: React.FC<Props> = ({
  accountForm,
  lang,
  onError,
  onAccept,
}: Props) => {
  return (
    <div className={styles.error}>
      <div>
        <span className={styles.errorSpan}>:(</span>
        <p className={styles.errorMessage}>{accountForm.submitError[lang]}</p>
      </div>
    </div>
  )
}

export default ErrorScreen
