const url = typeof window !== 'undefined' ? window.location.host : ''

const instanceUrl = url.includes('localhost:8000') || url.includes('anketa-dev') ?
    'https://irenta.hopto.org/' : //'http://localhost:5005/' :
    url.includes('anketa-msk') ?
    'https://msk.ka.irenta.online/' :
    url.includes('anketa-scnd') || url.includes('anketa.scnd') ?
    'https://second.ka.irenta.online/' :
    'https://kz.ka.irenta.online/'

export const INSTANCE = url.includes('localhost:8000') || url.includes('anketa-dev') ?
'ala' :
url.includes('anketa-msk') ?
'msk' :
url.includes('anketa-scnd') || url.includes('anketa.scnd') ?
'second' :
'ala'

export const COUNTRY = INSTANCE === 'ala' ? 'kz' : 'rf' 
export const PUBLIC_URL = instanceUrl + 'public/api'
export const API_URL = instanceUrl + 'public/api'
// и поменять BASE_URL когда появятся другие инстансы
export const BASE_URL = url.includes('anketa-scnd') || url.includes('anketa.scnd') ? 'https://second.ka.irenta.online/' : url.includes('anketa-msk') ? 'https://anketa-msk.irenta.online/' : 'https://anketa.irenta.online'
export const BACKEND_URL = instanceUrl + 'spd'
export const GET_PRODUCTS = instanceUrl + 'spd/anketa/catalog'

export const UPLOADS_FIELD_NAME = 'upload'
export const UPLOADS_ENDPOINT = `${BACKEND_URL}/uploads`
export const DOCUMENTS_REL_PATH = '/documents'
export const SUBMIT_ENDPOINT = `${BACKEND_URL}/submit`
export const SUBMIT_STATUS = `${BACKEND_URL}/status`
export const TOKEN_ENDPOINT = `${BACKEND_URL}/token`
export const ORDER_TOKEN_ENDPOINT = `${API_URL}/account/token`
export const ERRORS_ENDPOINT = `${BACKEND_URL}/error`
export const FORM_EXISTS_ENDPOINT = `${BACKEND_URL}/form-exists/`
export const USER_EXISTS_ENDPOINT = `${BACKEND_URL}/user-exists/`

export const SUBMIT_SUCCESS = `${BASE_URL}/success`
export const SUBMIT_SUCCESS_FISCAL = `${BASE_URL}/allsuccess`

export const CODE_SEND_ENDPOINT = `${BACKEND_URL}/sendcode`
export const CODE_CONFIRM_ENDPOINT = `${BACKEND_URL}/codeconfirm`
export const CODE_CONFIRM_ENABLED = `${BACKEND_URL}/isconfirmation`
