export const getCertificateRegistrationNumber = (fiscalType: string) => {
  return {
    label: {
      eng: `${
        fiscalType === '1'
          ? 'Series and number of Certificate*'
          : fiscalType === '3'
          ? 'Scan certificate. state JSC registration*'
          : fiscalType === '4'
          ? 'Scan certificate. state PJSC registration*'
          : fiscalType === '5'
          ? 'Scan certificate. state CJSC registration*'
          : fiscalType === '7'
          ? 'Registration number*'
          : 'Registration number*'
      }`,
      rus: `${
        fiscalType === '1'
          ? 'Серия и номер Свидетельства*'
          : fiscalType === '3'
          ? 'Cвид. гос. регистрации АО (cерия и номер)*'
          : fiscalType === '4'
          ? 'Cвид. гос. регистрации ПАО (cерия и номер)*'
          : fiscalType === '5'
          ? 'Cвид. гос. регистрации ЗАО (cерия и номер)*'
          : fiscalType === '7'
          ? 'Регистрационный номер*'
          : 'Регистрационный номер*'
      }`,
      kz: `${
        fiscalType === '1'
          ? 'Куәліктің сериясы мен нөмірі*'
          : fiscalType === '3'
          ? 'Сканерлеу сертификаты. күй АҚ тіркеу*'
          : fiscalType === '4'
          ? 'Сканерлеу сертификаты. күй ЖАҚ тіркеу*'
          : fiscalType === '5'
          ? 'Сканерлеу сертификаты. күй ЖАҚ тіркеу*'
          : fiscalType === '7'
          ? 'Тіркеу нөмірі*'
          : 'Тіркеу нөмірі*'
      }`,
    },
    placeholder: {
      eng: 'xxxx xxxxxxx',
      rus: 'xxxx xxxxxxx',
      kz: 'xxxx xxxxxxx',
    },
    invalidText: {
      eng: 'Enter the series and number correctly',
      rus: 'Введите серию и номер корректно ',
      kz: 'Серия мен нөмірді дұрыс енгізіңіз',
    },
  }
}

export const getCertificateRegistrationNumberRf = (fiscalType: string) => {
  return {
    label: {
      eng: `${
        fiscalType === '1'
          ? 'Certificate of registration of an IE*'
          : fiscalType === '3'
          ? 'Scan certificate. state JSC registration*'
          : fiscalType === '4'
          ? 'Scan certificate. state PJSC registration*'
          : fiscalType === '5'
          ? 'Scan certificate. state CJSC registration*'
          : fiscalType === '7'
          ? 'Registration number*'
          : 'Registration number*'
      }`,
      rus: `${
        fiscalType === '1'
          ? 'Свидетельство о регистрации ИП*'
          : fiscalType === '3'
          ? 'Cвид. гос. регистрации АО (cерия и номер)*'
          : fiscalType === '4'
          ? 'Cвид. гос. регистрации ПАО (cерия и номер)*'
          : fiscalType === '5'
          ? 'Cвид. гос. регистрации ЗАО (cерия и номер)*'
          : fiscalType === '7'
          ? 'Регистрационный номер*'
          : 'Регистрационный номер*'
      }`,
      kz: `${
        fiscalType === '1'
          ? 'ЖК тіркеу туралы куәлік*'
          : fiscalType === '3'
          ? 'Сканерлеу сертификаты. күй АҚ тіркеу*'
          : fiscalType === '4'
          ? 'Сканерлеу сертификаты. күй ЖАҚ тіркеу*'
          : fiscalType === '5'
          ? 'Сканерлеу сертификаты. күй ЖАҚ тіркеу*'
          : fiscalType === '7'
          ? 'Тіркеу нөмірі*'
          : 'Тіркеу нөмірі*'
      }`,
    },
    placeholder: {
      eng: 'xx xxxxxxxxx',
      rus: 'xx xxxxxxxxx',
      kz: 'xx xxxxxxxxx',
    },
    invalidText: {
      eng: 'Enter the series and number correctly',
      rus: 'Введите серию и номер корректно ',
      kz: 'Серия мен нөмірді дұрыс енгізіңіз',
    },
  }
}

