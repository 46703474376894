import React, { useState, useEffect } from 'react'

import { useDefer } from '../../utils/helpers'

import 'react-phone-input-2/lib/style.css'

import { validateEmail } from '../../validate/validators'
import AccountForm from '../../types/account-form.types'
import { Lang } from '../../types/common-types'
import styles from './EmailInput.module.scss'

interface Props {
  emails: string[]
  setEmails: (s: string[]) => void
  accountForm: AccountForm
  lang: Lang
  valid: boolean
  required?: boolean
  firstlocked?: boolean
  placeholder?: string
  invalidText?: string
  invalidTextCorrect?: string
  mailCheck?: boolean
  disabled?: boolean
  [prop: string]: any
}

export default function EmailInput({
  emails,
  setEmails,
  accountForm,
  lang,
  valid,
  required,
  firstlocked,
  placeholder,
  invalidText,
  invalidTextCorrect,
  mailCheck,
  disabled,
  ...props
}: Props) {
  // There is always must be at least one input field
  //const [state, setState] = useState(emails.length === 0 ? [''] : emails)

  // Update upper state after some time on changes.
  // We manualy update state when delete field (see delete button onClick handler)
  const [inOnChangeInput, setInOnChangeInput] = useState(false)

  useDefer(
    () => {
      if (inOnChangeInput) {
        setInOnChangeInput(false)
        setEmails(emails)
      }
    },
    [emails],
    300
  )
  const [deleting, setDeleting] = useState(false)
  const addFieldBtnClassName = deleting
    ? `${styles.animateDeleting} ${styles.addField}`
    : styles.addField

  const [lastInput, setLastInput] = useState(emails.length - 1)
  useEffect(() => {
    setLastInput(emails.length - 1)
  }, [emails.length])

  return (
    <section className={styles.container}>
      <label className={styles.label} htmlFor={`email-${lastInput}`}>
        <p className={styles.labelText}>
          {accountForm.email.label[lang]}
          {required && <span className={styles.required}></span>}
        </p>
      </label>
      <ul className={styles.list}>
        {emails.map((email, index) => (
          <li key={index} className={styles.listItem}>
            <div
              className={`${(!valid || !validateEmail(email)) &&
                styles.invalid} ${styles.inputAndButtonWrapper}`.trim()}
            >
              <input
                data-test-id={props.testName}
                id={`email-${index}`}
                type="email"
                placeholder={placeholder}
                value={email}
                disabled={(index === 0 && firstlocked) || disabled}
                onChange={({ target }) => {
                  const { value } = target
                  // Set state with new array of emails.
                  // New array is a copy of previous one with all indices kept
                  // only current value changed under current index.
                  // It is same as we set array[index] = newValue but instead
                  // mutating old array we get new one.
                  // Get parts of an array without current item
                  const [firsPart, lastPart] = [
                    // part before current item
                    emails.slice(0, index),
                    // part after current item
                    emails.slice(index + 1),
                  ]
                  setEmails([...firsPart, value, ...lastPart])
                  setInOnChangeInput(true)
                }}
                className={styles.input}
              />
            </div>
          </li>
        ))}
      </ul>
      {mailCheck ? (
        <span className={styles.invalidText}>
          {accountForm.emailWarningMessage[lang]}
        </span>
      ) : !valid && invalidText ? (
        emails[0].length > 0 ? (
          invalidText ? (
            <span
              data-test-id={props.testErrorName}
              className={styles.invalidText}
            >
              {invalidText}
            </span>
          ) : null
        ) : (
          <span
            data-test-id={props.testErrorName}
            className={styles.invalidText}
          >
            {invalidText}
          </span>
        )
      ) : null}
    </section>
  )
}
