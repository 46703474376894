import React from 'react'

import styles from './BlueButton.module.scss'

interface Props {
  value: string
  onClick: any
  className?: string
  disabled?: boolean
  testName?: string
}
export default function BlueButton({
  value,
  onClick,
  className,
  disabled,
  testName,
}: Props) {
  return (
    <button
      data-test-id={testName}
      onClick={onClick}
      className={styles.BlueButton + ' ' + className}
      disabled={disabled}
    >
      {value}
    </button>
  )
}
