import React, { useEffect, useMemo } from 'react'
import styles from './IdentityExist.module.scss'
import BlueButton from './Buttons/BlueButton'
import AccountForm from '../types/account-form.types'
import { State } from '../state-actions-reducers'
import useDebounce from "../utils/useDebounce";
import {IdentityCardDtoModel, searchByIdentity} from "../hooks/useSearchByIdentityHook";
import { convToInt } from '../utils/helpers'
import { Dispatch, actions } from '../state-actions-reducers'
import { searchByInn } from '../hooks/useSearchByInnHook'

interface Props {
  state: State
  accountForm: AccountForm
  lang: string
  dispatch: Dispatch
  show: boolean
}
function UrExist({
  state,
  accountForm,
  lang,
  dispatch,
  show
}: Props) {

  const onInnChange = async() => {
    const response = await searchByInn(state, state.F_INN, state.F_FiscalType, state.citizenship)
    if (response.message !== 'Invalid token') {
      dispatch({ type: actions.IS_IDENTITY_OK, data: response.isInnOk })
    } else {
      alert('Произошла ошибка, перезагрузите странницу!')
    }
  }

  useEffect(() => {
    state.F_INN.length >= 10 ? onInnChange() : dispatch({ type: actions.IS_IDENTITY_OK, data: true })
  }, [state.F_INN]);

  return (
    show ? <div className="col-12">
      <div className={styles.identityExistContainer}>
        <b>{accountForm.identityExistHead[lang]}</b>
        <p>{accountForm.identityExistDesc[lang]}</p>
        <BlueButton value={accountForm.identityCallback[lang]} onClick={() => window.open('tel:77003571338', '_self')} />
      </div>
    </div> : null
  )
}

export default UrExist
