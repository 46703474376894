// TODO: Handle server errors. Now user can't proceed if server responded with
// error

import React from 'react'

import { Lang, Country, ScreenProps } from '../../types/common-types'
import { State, Dispatch, actions } from '../../state-actions-reducers'

import AccountForm from '../../types/account-form.types'

import styles from './FiscalScreenThree.module.scss'
import ProductsInput from '../../components/Inputs/ProductsInput'

// import downloadFile2 from '../static/Форма согласия.docx'

interface Props extends ScreenProps {
  lang: Lang
  state: State
  dispatch: Dispatch
  accountForm: AccountForm
  invalidFields: string[]
  citizenshipCountriesList: Country[]
  allCitizenships: Country[]
}

/* THE COMPONENT */
function FiscalScreenFour({ lang, state, dispatch, accountForm }: Props) {
  return (
    <>
      <main className={styles.main}>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3>{accountForm.stepThreeHeader[lang]}</h3>
              <p>{accountForm.stepThreeHeaderDesc[lang]}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ProductsInput
                state={state}
                lang={lang}
                accountForm={accountForm}
                initialValue={state.goods}
                dispatch={dispatch}
                onChange={data => {
                  dispatch({
                    type: actions.GOODS,
                    data,
                  })
                }}
                valid={true}
                required
              />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default FiscalScreenFour
