import React from 'react'
import { components } from 'react-select'

const SelectInputDropdownButton: React.FC = (props: any) => (
  <components.DropdownIndicator {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.349995 14.774996">
      <path
        d="M 0,0 9.175,-14.775 18.35,0 Z"
        fill="#f05938"
        transform="scale(0.99999972,-0.99999972)"
      />
    </svg>
  </components.DropdownIndicator>
)

export default SelectInputDropdownButton
