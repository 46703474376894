import { Option } from '../types/common-types'

export const kzCity = [
  {
    value: 'ala',
    label: {
      eng: 'Almaty',
      rus: 'Алматы',
      kz: 'Алматы',
    },
  },
]
export const rfCity = [
  {
    value: 'spb',
    label: {
      eng: 'Saint Petersburg',
      rus: 'Санкт-Петербург',
      kz: 'Санкт-Петербург',
    },
  },
  {
    value: 'msk',
    label: {
      eng: 'Moscow',
      rus: 'Москва',
      kz: 'Мәскеу',
    },
  },
]
