export const getCertificateRegistrationImage = (fiscalType: string) => {
  return {
    label: {
      eng: `${
        fiscalType === '1'
          ? 'Scan of registration document*'
          : fiscalType === '3'
          ? 'Scan certificate. state JSC registration*'
          : fiscalType === '4'
          ? 'Scan certificate. state PJSC registration*'
          : fiscalType === '5'
          ? 'Scan certificate. state CJSC registration*'
          : fiscalType === '6'
          ? 'Scan certificate. state LLP registration*'
          : 'Scan certificate. state LLP registration*'
      }`,
      rus: `${
        fiscalType === '1'
          ? 'Скан свид. гос. регистрации ИП*'
          : fiscalType === '3'
          ? 'Скан свид. гос. регистрации АО*'
          : fiscalType === '4'
          ? 'Скан свид. гос. регистрации ПАО*'
          : fiscalType === '5'
          ? 'Скан свид. гос. регистрации ЗАО*'
          : fiscalType === '6'
          ? 'Скан свид. гос. регистрации ТОО*'
          : 'Скан свид. гос. регистрации ООО*'
      }`,
      kz: `${
        fiscalType === '1'
          ? 'Сканерлеу сертификаты. күй IP тіркеу*'
          : fiscalType === '3'
          ? 'Сканерлеу сертификаты. күй АҚ тіркеу*'
          : fiscalType === '4'
          ? 'Сканерлеу сертификаты. күй ЖАҚ тіркеу*'
          : fiscalType === '5'
          ? 'Сканерлеу сертификаты. күй ЖАҚ тіркеу*'
          : fiscalType === '6'
          ? 'Сканерлеу сертификаты. күй ЖШС тіркеу*'
          : 'Сканерлеу сертификаты. күй ЖШС тіркеу*'
      }`,
    },
    placeholder: {
      eng: 'Select or drag a file',
      rus: 'Выберите или перетащите файл',
      kz: 'Файлды таңдаңыз немесе сүйреңіз',
    },
  }
}
