import React from 'react'

import styles from './HollowButton.module.scss'

interface Props {
  value: string
  onClick: any
  className?: string
  testName?: string
}
export default function HollowButton({
  value,
  onClick,
  className,
  testName,
}: Props) {
  return (
    <button
      data-test-id={testName}
      onClick={onClick}
      className={styles.HollowButton + ' ' + className}
    >
      {value}
    </button>
  )
}
