export const getFiscalShortName = (
  fiscalType: string,
  countryRegistration: string
) => {
  return {
    label: {
      eng: `${
        fiscalType === '1)'
          ? 'Abbreviated name IP*'
          : fiscalType === '3'
          ? 'Abbreviated name JSC*'
          : fiscalType === '4'
          ? 'Abbreviated name PJSC*'
          : fiscalType === '5'
          ? 'Abbreviated name CJSC*'
          : fiscalType === '7'
          ? 'Abbreviated name LLP*'
          : 'LLC'
      }`,
      rus: `${
        fiscalType === '1'
          ? 'Сокращенное наименование ИП*'
          : fiscalType === '3'
          ? 'Сокращенное наименование АО*'
          : fiscalType === '4'
          ? 'Сокращенное наименование ПАО*'
          : fiscalType === '5'
          ? 'Сокращенное наименование ЗАО*'
          : fiscalType === '7'
          ? 'Сокращенное наименование ОсОО*'
          : 'Сокращенное наименование ООО*'
      }`,
      kz: `${
        fiscalType === '1'
          ? 'Қысқартылған атау IP*'
          : fiscalType === '3'
          ? 'Қысқартылған атау АҚ*'
          : fiscalType === '4'
          ? 'Қысқартылған атау ЖАҚ*'
          : fiscalType === '5'
          ? 'Қысқартылған атау ЖАҚ*'
          : fiscalType === '7'
          ? 'Қысқартылған атау ЖШС*'
          : 'Қысқартылған атау ЖШС*'
      }`,
    },
    placeholder: {
      eng: `${
        fiscalType === '1'
          ? countryRegistration === '417'
            ? 'IP «Lastochka»'
            : 'IP «MPK»'
          : fiscalType === '3'
          ? countryRegistration === '417'
            ? 'JSC «APK»'
            : 'JSC «MPK»'
          : fiscalType === '4'
          ? countryRegistration === '417'
            ? 'PJSC «APK»'
            : 'PJSC «MPK»'
          : fiscalType === '5'
          ? countryRegistration === '417'
            ? 'CJSC «APK»'
            : 'CJSC «MPK»'
          : fiscalType === '7'
          ? countryRegistration === '417'
            ? 'LLP «APK»'
            : 'LLP «MPK»'
          : 'LLC «MPK'
      }`,
      rus: `${
        fiscalType === '1'
          ? countryRegistration === '417'
            ? 'ИП «Ласточка»'
            : 'ИП «MПK»'
          : fiscalType === '3'
          ? countryRegistration === '417'
            ? 'АО «АПK»'
            : 'АО «MПK»'
          : fiscalType === '4'
          ? countryRegistration === '417'
            ? 'ПАО «АПK»'
            : 'ПАО «MПK»'
          : fiscalType === '5'
          ? countryRegistration === '417'
            ? 'ЗАО «АПK»'
            : 'ЗАО «MПK»»'
          : fiscalType === '7' && countryRegistration === '417'
          ? 'ОсОО «АПK»'
          : 'ООО «MПK»'
      }`,
      kz: `${
        fiscalType === '1'
          ? countryRegistration === '417'
            ? 'IP «Martïn»'
            : 'IP «MPK»'
          : fiscalType === '3'
          ? countryRegistration === '417'
            ? 'АҚ «APK»'
            : 'АҚ «MPK»'
          : fiscalType === '4'
          ? countryRegistration === '417'
            ? 'ЖАҚ «APK»'
            : 'ЖАҚ «MPK»'
          : fiscalType === '5'
          ? countryRegistration === '417'
            ? 'ЖАҚ «APK»'
            : 'ЖАҚ «MPK»'
          : fiscalType === '7'
          ? countryRegistration === '417'
            ? 'ЖШС «APK»'
            : 'ЖШС «MPK»'
          : 'ЖШС «MPK»'
      }`,
    },
  }
}
