// TODO: Handle server errors. Now user can't proceed if server responded with
// error

import React, { useState, useEffect } from 'react'

import { Lang } from '../../types/common-types'
import { State, Dispatch, actions } from '../../state-actions-reducers'
import AccountForm from '../../types/account-form.types'
import CompanyScreenTwoKzt from './Company/KztRegistration/CompanyScreenTwo'
import CompanyScreenTwoRus from './Company/RusRegistration/CompanyScreenTwo'
import CompanyScreenTwoKgz from './Company/KgzRegistration/CompanyScreenTwo'

interface Props {
  lang: Lang
  state: State
  dispatch: Dispatch
  accountForm: AccountForm
  invalidFields: string[]
}

/* THE COMPONENT */
function FiscalScreenTwo({
  lang,
  state,
  dispatch,
  accountForm,
  invalidFields,
}: Props) {
  return (
    <>
      {// Казахстан
      state.countryRegistration === '398' ? (
        <CompanyScreenTwoKzt
          lang={lang}
          state={state}
          dispatch={dispatch}
          accountForm={accountForm}
          invalidFields={invalidFields}
        />
      ) : // Киргизия
      state.countryRegistration === '417' ? (
        <CompanyScreenTwoKgz
          lang={lang}
          state={state}
          dispatch={dispatch}
          accountForm={accountForm}
          invalidFields={invalidFields}
        />
      ) : (
        <CompanyScreenTwoRus
          lang={lang}
          state={state}
          dispatch={dispatch}
          accountForm={accountForm}
          invalidFields={invalidFields}
        />
      )}
    </>
  )
}

export default FiscalScreenTwo
