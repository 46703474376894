import {
  API_URL,
  BACKEND_URL,
  TOKEN_ENDPOINT,
} from '../server-parameters'
import { LOCAL_STORAGE_KEYS } from '../constant'
import { State } from '../state-actions-reducers'
import { getUserAndFormId } from './useTokenCustomHook'

// const headers = new Headers({
// 	'content-type': 'application/json',
// 	accept: 'text/plain'
// })

export async function searchByIdentity(state: State, identity: IdentityCardDtoModel) {
  const headers = new Headers({
    'content-type': 'application/json',
    accept: 'text/plain',
  })
  if (!headers.has('authorization')) {
    const token = state.formId
    headers.set('Authorization', 'Bearer ' + token)
  }

  const fullUrl = BACKEND_URL + '/anketa/checkIdentity '

  const formData = {
    ...identity
  }
  const fetchOptions = {
    headers,
    body: JSON.stringify(formData),
    method: 'POST',
  }
  let response = await fetch(fullUrl, fetchOptions)
  if (
    response.status === 400 ||
    response.status === 401 ||
    response.status === 403
  ) {
    getUserAndFormId(() => {}, window.localStorage)
  }
  const body = await response.json()
  //console.log(body)
  return body
}

export type IdentityCardDtoModel = {
  identityType?: number;

  series?: string;
  number?: string;
  authorityCode?: string;
  dateIssue?: string;
  issuedBy?: string;
  personNumber?: string;
  firstNationality?: string;
  identificationCardNumber?: string;
  dateExpiry?: string;
  isPermanentResidency?: boolean | null;
  permanentResidencyNumber?: string;

  dateIssuePermanentResidency?: string;

  internationalPassportNumber?: string;
  dateBoardCrossing?: string;
  isDateExpires?: boolean;
}
