import React, { useEffect, useMemo } from 'react'
import styles from './IdentityExist.module.scss'
import BlueButton from './Buttons/BlueButton'
import AccountForm from '../types/account-form.types'
import { State } from '../state-actions-reducers'
import useDebounce from "../utils/useDebounce";
import {IdentityCardDtoModel, searchByIdentity} from "../hooks/useSearchByIdentityHook";
import { convToInt } from '../utils/helpers'
import { Dispatch, actions } from '../state-actions-reducers'

interface Props {
  state: State
  accountForm: AccountForm
  lang: string
  dispatch: Dispatch
  show: boolean
}

export function splitPassportNumber(input: string): [string, string] {
  // 4313-343 434
  input = input.replace(/[^\d]/g, ''); // Удаляем все символы, кроме цифр
  // 4313343 434
  input = input.replace(' ', ''); // Удаляем все символы, кроме цифр
  // 4313343434
  input = input.substring(0,2)+' '+input.substring(2);
  // 43 13343434
  const firstPart = input.substring(0, 5);
  // 43 13
  const secondPart = input.substring(input.length - 6);
  // 343434
  return [firstPart, secondPart];
}

function IdentityExist({
  state,
  accountForm,
  lang,
  dispatch,
  show
}: Props) {

  const debouncePassportType = useDebounce(state.passportType, 1500)
  const debouncePassportSeries = useDebounce(state.passportSeries, 1500)
  const debouncePassportNumber = useDebounce(state.passportNumber, 1500)
  const debouncePassportIAt = useDebounce(state.passportIAt, 1500)
  const debouncePassportIssuedBy = useDebounce(state.passportIssuedBy, 1500)
  const debouncePassportAuthorityCode = useDebounce(state.passportAuthorityCode, 1500)
  const debouncePassportPersonNumber = useDebounce(state.passportPersonNumber, 1500)
  const debouncePassportFirstNationality = useDebounce(state.passportFirstNationality, 1500)
  const debouncePassportCardNumber = useDebounce(state.passportCardNumber, 1500)
  const debouncePassportExpiryDate = useDebounce(state.passportExpiryDate, 1500)
  const debouncePassportIsPermanentResidency = useDebounce(state.passportIsPermanentResidency, 1500)
  const debouncePassportDateIssuePermanentResidency = useDebounce(state.passportDateIssuePermanentResidency, 1500)
  const debouncePassportPermanentResidencyNumber = useDebounce(state.passportPermanentResidencyNumber, 1500)
  const debouncePassportInternationalNumber = useDebounce(state.passportInternationalNumber, 1500)
  const debouncePassportDateBoardCrossing = useDebounce(state.passportDateBoardCrossing, 1500)

  const [passportSeries, passportNumber] = splitPassportNumber(debouncePassportNumber)

  const identity: IdentityCardDtoModel = useMemo(() => ({
    identityType: convToInt(debouncePassportType)??undefined,
    series: state.citizenship === "643" ? passportSeries.replace(' ', '') : debouncePassportSeries.replace(' ', ''),
    number: state.citizenship === "643" ? passportNumber.replace(' ', '') : debouncePassportNumber.replace(' ', ''),
    dateIssue: debouncePassportIAt,
    issuedBy: debouncePassportIssuedBy,
    authorityCode: debouncePassportAuthorityCode,
    personNumber: debouncePassportPersonNumber.replace(' ', ''),
    firstNationality: debouncePassportFirstNationality,
    identificationCardNumber: debouncePassportCardNumber.replace(' ', ''),
    dateExpiry: debouncePassportExpiryDate,
    isPermanentResidency: debouncePassportIsPermanentResidency,
    dateIssuePermanentResidency: debouncePassportDateIssuePermanentResidency,
    permanentResidencyNumber: debouncePassportPermanentResidencyNumber.replace(' ', ''),
    internationalPassportNumber: debouncePassportInternationalNumber.replace(' ', ''),
    dateBoardCrossing: debouncePassportDateBoardCrossing,
    citizenship: state.citizenship
  }),
  [debouncePassportType, debouncePassportSeries, debouncePassportNumber, debouncePassportIAt, debouncePassportIssuedBy,
  debouncePassportAuthorityCode, debouncePassportPersonNumber, debouncePassportFirstNationality, debouncePassportCardNumber,
  debouncePassportExpiryDate, debouncePassportIsPermanentResidency, debouncePassportDateIssuePermanentResidency,
  debouncePassportPermanentResidencyNumber, debouncePassportInternationalNumber, debouncePassportDateBoardCrossing]);

  const onIdentityChange = async() => {
    const response = await searchByIdentity(state, identity)
    if (response.message !== 'Invalid token') {
      dispatch({ type: actions.IS_IDENTITY_OK, data: response.isIdentityOk })
    } else {
      alert('Произошла ошибка, перезагрузите странницу!')
    }
  }

  useEffect(() => {
    onIdentityChange()
  }, [identity]);

  return (
    show ? <div className="col-12">
      <div className={styles.identityExistContainer}>
        <b>{accountForm.identityExistHead[lang]}</b>
        <p>{accountForm.identityExistDesc[lang]}</p>
        <BlueButton value={accountForm.identityCallback[lang]} onClick={() => window.open('tel:77003571338', '_self')} />
      </div>
    </div> : null
  )
}

export default IdentityExist
