// TODO: Handle server errors. Now user can't proceed if server responded with
// error

import React, { useState, useEffect } from 'react'

import { Lang } from '../../../../types/common-types'
import { State, Dispatch, actions } from '../../../../state-actions-reducers'
import AccountForm from '../../../../types/account-form.types'
import TextInput from '../../../../components/Inputs/TextInput'
import AddButton from '../../../../components/Buttons/AddButton'
import DeleteButton from '../../../../components/Buttons/DeleteButton'
import InformationImage from '../../../../images/informationIcon.svg'
import { getBanks, getBanksKz } from '../../../../hooks/useBanksCustomHook'
import {
  validateAddress,
  validateName,
  stringDigitsLength,
  smartValidateString,
} from '../../../../validate/validators'
import styles from '../../FiscalScreenTwo.module.scss'

interface Props {
  lang: Lang
  state: State
  dispatch: Dispatch
  accountForm: AccountForm
  invalidFields: string[]
}

/* THE COMPONENT */
function CompanyScreenTwoKzt({
  lang,
  state,
  dispatch,
  accountForm,
  invalidFields,
}: Props) {
  const [header, ...listItems] =
    state.F_FiscalType === '1'
      ? accountForm.uploadF_DocTextIP[lang].split('%li%')
      : accountForm.uploadF_DocText[lang].split('%li%')

  const [focus, setFocus] = useState('')

  async function autoFillKz(bik: string, i: number, value: string) {
    let response = await getBanksKz(bik),
      name = response?.names?.[lang]
    name &&
      dispatch({
        type: actions.BANK_DETAILS,
        data: state.BankDetails.map((item, key) => {
          return key === i
            ? value === 'name' && name
              ? { ...item, F_BankName: name, F_BankBIK: bik }
              : { ...item }
            : { ...item }
        }),
      })
  }

  const [banksCount, setbanksCount] = useState(state.BankDetails.length || 1)
  const banks = () => {
    let banksList = []
    for (let i = 0; i < banksCount; i++) {
      {
        banksList.push(
          <div className={styles.banksRow} key={i}>
            <div className="row">
              <div className="col-3 col-xs-12">
                {/* F_BankRS */}
                <TextInput
                  testName={`F_BankRS-${i}`}
                  testErrorName={`error-F_BankRS-${i}`}
                  disabled={!state.agreed}
                  lang={lang}
                  content={accountForm.F_BankRS_KZT}
                  onChange={F_BankRS => {
                    dispatch({
                      type: actions.BANK_DETAILS,
                      data: state.BankDetails.map((item, key) => {
                        return key === i
                          ? { ...item, F_BankRS: F_BankRS.replace(/_/gim, '') }
                          : { ...item }
                      }),
                    })
                  }}
                  // onChange={F_BankRS =>
                  //   dispatch({ type: actions.F_BankRS, data: F_BankRS })
                  // }
                  value={
                    state.BankDetails[i] ? state.BankDetails[i].F_BankRS : ''
                  }
                  valid={!invalidFields.includes(`F_BankRS${i}`)}
                  mask="********************"
                  required
                />
              </div>
              <div className="col-2 col-xs-12">
                {/* F_BankBIK */}
                <TextInput
                  testName={`F_BankBIK-${i}`}
                  testErrorName={`error-F_BankBIK-${i}`}
                  disabled={!state.agreed}
                  lang={lang}
                  content={accountForm.F_BankBIK_KZT}
                  onChange={F_BankBIK => {
                    dispatch({
                      type: actions.BANK_DETAILS,
                      data: state.BankDetails.map((item, key) => {
                        return key === i
                          ? {
                              ...item,
                              F_BankBIK: F_BankBIK.replace(/_/gim, ''),
                            }
                          : { ...item }
                      }),
                    })
                    if (F_BankBIK.replace(/_/gim, '').length >= 8)
                      autoFillKz(F_BankBIK.replace(/_/gim, ''), i, 'name')
                  }}
                  // onChange={F_BankBIK =>
                  //   dispatch({ type: actions.F_BankBIK, data: F_BankBIK })
                  // }
                  value={
                    state.BankDetails[i] ? state.BankDetails[i].F_BankBIK : ''
                  }
                  valid={!invalidFields.includes(`F_BankBIK${i}`)}
                  mask={'***********'}
                  required
                />
              </div>
              <div className="col-6 col-xs-12">
                {/* F_BankName */}
                <TextInput
                  testName={`F_BankName-${i}`}
                  testErrorName={`error-F_BankName-${i}`}
                  disabled={!state.agreed}
                  lang={lang}
                  content={accountForm.F_BankName_KZT}
                  onChange={F_BankName => {
                    dispatch({
                      type: actions.BANK_DETAILS,
                      data: state.BankDetails.map((item, key) => {
                        return key === i
                          ? { ...item, F_BankName: F_BankName }
                          : { ...item }
                      }),
                    })
                  }}
                  value={
                    state.BankDetails[i] ? state.BankDetails[i].F_BankName : ''
                  }
                  valid={
                    state.BankDetails[i].F_BankName.length > 0
                      ? smartValidateString(state.BankDetails[i].F_BankName) &&
                        !invalidFields.includes(`F_BankName${i}`)
                      : !invalidFields.includes(`F_BankName${i}`)
                  }
                  onBlur={() => setFocus('')}
                  focus={focus === 'F_BankName' || undefined}
                />
                {/* <a
                  onClick={() => {
                    if (state.BankDetails[i].F_BankBIK)
                      autoFillKz(state.BankDetails[i].F_BankBIK, i, 'name')
                  }}
                  className={styles.autoFill}
                >
                  {accountForm.autoFill[lang]}
                </a>
                <img
                  src={InformationImage}
                  className={styles.informationImage}
                  alt="i"
                />
                <div className={styles.informationBox}>
                  {accountForm.Bank_AutoFill[lang]}
                </div> */}
              </div>
            </div>
            {i > 0 ? (
              <>
                <DeleteButton
                  className={styles.banksDeleteButton}
                  onClick={() => {
                    dispatch({
                      type: actions.BANK_DETAILS,
                      data: state.BankDetails.filter(
                        (item, index) => index !== i
                      ),
                    })
                    dispatch({
                      type: actions.BANK_DETAILS,
                      data: state.BankDetails.filter(
                        (item, index) => index !== i
                      ),
                    })
                    setbanksCount(banksCount - 1)
                  }}
                />
                <button
                  className={styles.banksMobileDeleteButton}
                  onClick={() => {
                    dispatch({
                      type: actions.BANK_DETAILS,
                      data: state.BankDetails.filter(
                        (item, index) => index !== i
                      ),
                    })
                    dispatch({
                      type: actions.BANK_DETAILS,
                      data: state.BankDetails.filter(
                        (item, index) => index !== i
                      ),
                    })
                    setbanksCount(banksCount - 1)
                  }}
                >
                  Удалить
                </button>
              </>
            ) : null}
          </div>
        )
      }
    }
    return banksList
  }
  const [banksList, setbanksList] = useState(banks)
  useEffect(() => {
    setbanksList(banks)
  }, [banksCount, state.BankDetails, invalidFields])

  return (
    <>
      <main className={styles.main}>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3>{accountForm.Fiscal_Bank_Requisites[lang]}</h3>
            </div>
          </div>
          {banksList}
          <div className="row">
            <div className="col-4 col-xs-12">
              <div
                className={styles.addButtonBox}
                onClick={() => {
                  setbanksCount(banksCount + 1)
                  dispatch({
                    type: actions.BANK_DETAILS,
                    data: state.BankDetails.concat({
                      F_BankBIK: '',
                      F_BankName: '',
                      F_BankRS: '',
                    }),
                  })
                }}
              >
                <AddButton />
                <span>{accountForm.addBanksButton[lang]}</span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="form-group">
          <div className="row">
            <div className="col-6 col-xs-12"> */}
        {/* F_TaxType */}
        {/* <SelectInput
                lang={lang}
                noOptionsMessage={accountForm.noOptionsMessage}
                content={accountForm.F_TaxType}
                onChange={data =>
                  dispatch({
                    type: actions.F_TaxType,
                    data,
                  })
                }
                value={state.F_TaxType}
                options={accountForm.F_TaxTypeValues}
                valid={
                  !invalidFields.includes('F_TaxType') &&
                  validateName(state.F_TaxType)
                }
                required
              /> */}
        {/* </div>
          </div>
        </div> */}
      </main>
    </>
  )
}

export default CompanyScreenTwoKzt
