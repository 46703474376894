/**
 * Here we show loading animation or error message.
 * We submit a form. Then every n seconds send request
 * to the '/status' endpoint to check status of the form.
 * If form is sent successfully, call <onNext> function
 * while still showing the loading animation.
 */

import React, { useState, useEffect, useRef, Key } from 'react'
import axios, { CancelToken } from 'axios'

import ErrorScreen from '../ErrorScreen'
import LoadingScreen from '../LoadingScreen'
import { Lang } from '../../types/common-types'
import { State } from '../../state-actions-reducers'
import AccountForm from '../../types/account-form.types'
import {
  SUBMIT_ENDPOINT,
  SUBMIT_STATUS,
} from '../../server-parameters'
import { useOrderSubmit } from '../../hooks/useOrderSubmitCustomHook'
import { useQueryContext } from '../../pages'

import styles from './ScreenFour.module.scss'
import { getPassportTypeTranslate } from '../../hooks/useGetPassportTypeTranslate'
import { splitPassportNumber } from '../../components/IdentityExist'

// We are waiting for VERIFICATION or ERROR from server
const SUCCESS = 'VERIFICATION'
const ERROR = 'ERROR'
const SUBMITTINGORDER = 'SUBMITTINGORDER'

interface Props {
  lang: Lang
  accountForm: AccountForm
  form: State
  cart: any
  resetState: () => void
  nextStep: () => void
  prevStep: () => void
}

interface Response {
  data: {
    ok: boolean
    message?: string
    data?: string
  }
}

interface SubmitArgs {
  form: State
  userId: string
  formId: string
  cancelToken: CancelToken
  onResponse: (status: string) => void
  type: 'SUBMIT' | 'CHECK'
  query?: string
  cart: any
}
interface SubmitOrderArgs {
  form: any
  cart: any
  onResponse: (status: string) => void
}
async function request({
  form,
  userId,
  formId,
  cancelToken,
  onResponse,
  type,
  // Query string for trackink UTM campaign
  query = '',
  cart,
}: SubmitArgs) {
  try {
    // Create request function depending on submitting or not

    form.isStudent =
      form.jobOrStudy === 'Учусь' ||
      form.jobOrStudy === 'Работаю и учусь' ||
      form.jobOrStudy === "I'm studying" ||
      form.jobOrStudy === 'I work and study' ||
      form.jobOrStudy === 'Мен оқып жатырмын' ||
      form.jobOrStudy === 'Жұмыс және оқу'
        ? true
        : false
    form.vk =
      form.additionalLinksDescription.includes('ВКонтакте') ||
      form.additionalLinksDescription.includes('VK')
        ? form?.additionalLinks?.filter((item, i) => {
            return (
              form.additionalLinksDescription[i] === 'ВКонтакте' ||
              form.additionalLinksDescription[i] === 'VK'
            )
          })
        : ['']
    form.instagram = form.additionalLinksDescription.includes('Instagram')
      ? form?.additionalLinks?.filter((item, i) => {
          return form.additionalLinksDescription[i] === 'Instagram'
        })
      : ['']
    form.whatsapp = form.additionalLinksDescription.includes('WhatsApp')
      ? form?.additionalLinks?.filter((item, i) => {
          return form.additionalLinksDescription[i] === 'WhatsApp'
        })
      : ['']

    form.youtube = form.additionalLinksDescription.includes('YouTube')
      ? form?.additionalLinks?.filter((item, i) => {
          return form.additionalLinksDescription[i] === 'YouTube'
        })
      : ['']

    form.odnoklassniki =
      form.additionalLinksDescription.includes('Одноклассники') ||
      form.additionalLinksDescription.includes('OK')
        ? form?.additionalLinks?.filter((item, i) => {
            return (
              form.additionalLinksDescription[i] === 'Одноклассники' ||
              form.additionalLinksDescription[i] === 'OK'
            )
          })
        : ['']

    form.facebook = form.additionalLinksDescription.includes('Facebook')
      ? form?.additionalLinks?.filter((item, i) => {
          return form.additionalLinksDescription[i] === 'Facebook'
        })
      : ['']

    form.viber = form.additionalLinksDescription.includes('Viber')
      ? form?.additionalLinks?.filter((item, i) => {
          return form.additionalLinksDescription[i] === 'Viber'
        })
      : ['']

    form.twitter = form.additionalLinksDescription.includes('X (Twitter)')
      ? form?.additionalLinks?.filter((item, i) => {
          return form.additionalLinksDescription[i] === 'X (Twitter)'
        })
      : ['']

    form.skype = form.additionalLinksDescription.includes('Skype')
      ? form?.additionalLinks?.filter((item, i) => {
          return form.additionalLinksDescription[i] === 'Skype'
        })
      : ['']

    form.vimeo = form.additionalLinksDescription.includes('Vimeo')
      ? form?.additionalLinks?.filter((item, i) => {
          return form.additionalLinksDescription[i] === 'Vimeo'
        })
      : ['']

    form.additionalLinks = form.additionalLinksDescription.includes('Другое')
      ? form?.additionalLinks?.filter((item, i) => {
          return form.additionalLinksDescription[i] === 'Другое'
        })
      : [''];
    if (form.citizenship === "643" && getPassportTypeTranslate(+form.passportType) === 'Паспорт') {
      const [passportSeries, passportNumber] = splitPassportNumber(form.passportNumber)
      form.passportSeries = passportSeries.replace(' ', '')
      form.passportNumber = passportNumber.replace(' ', '')
    }
    const requestFn: () => Promise<Response> =
      type === 'SUBMIT'
        ? // if submitting
          () =>
            axios.post(
              SUBMIT_ENDPOINT,
              {
                query,
                userId,
                formId,
                form: {
                  ...form,
                  userId,
                  formId,
                },
              },
              { cancelToken }
            )
        : // if checking status
          () =>
            axios.post(
              SUBMIT_STATUS,
              {
                userId,
                formId,
              },
              { cancelToken }
            )
    // Make request
    const { data } = await requestFn()
    if (!data) throw new Error('Request returned no data')
    const { ok, message, data: status } = data
    if (!ok || !status) {
      const errorMessage = `
Request returned error or no status:
message: ${message}
status: ${status}
`.trim()
      throw new Error(errorMessage)
    }
    // Everything is good
    if (cart && cart.length > 0) return onResponse(SUBMITTINGORDER)
    else return onResponse(status)
  } catch (e) {
    // Do nothing on cancelation of the request
    if (axios.isCancel(e)) {
      console.log('Request has been canceled')
      return
    }
    console.error('Error making request:\n', e)
    return onResponse(ERROR)
  }
}

async function requestOrder({ form, cart, onResponse }: SubmitOrderArgs) {
  let orderList: any = []
  cart.forEach((cartItem: any) => {
    orderList.length > 0
      ? orderList.forEach((order: any, orderIdx: Key) => {
          cartItem.startDateIso === order.startDate &&
          cartItem.endDateIso === order.endDate
            ? order.items.push({
                guid: cartItem.guid,
                id: cartItem.id,
                count: cartItem.count,
                discount: cartItem.discount,
              })
            : orderIdx === orderList.length - 1
            ? orderList.push({
                items: [
                  {
                    guid: cartItem.guid,
                    id: cartItem.id,
                    count: cartItem.count,
                    discount: cartItem.discount,
                  },
                ],
                startDate: cartItem.startDateIso,
                endDate: cartItem.endDateIso,
              })
            : null
        })
      : orderList.push({
          items: [
            {
              guid: cartItem.guid,
              id: cartItem.id,
              count: cartItem.count,
              discount: cartItem.discount,
            },
          ],
          startDate: cartItem.startDateIso,
          endDate: cartItem.endDateIso,
        })
  })
  orderList.forEach((orderItem: any) => {
    useOrderSubmit(
      form,
      orderItem.startDate,
      orderItem.endDate,
      orderItem.items
    )
  })
  return onResponse(SUCCESS)
}

const STATES = {
  SUBMITTING: Symbol('SUBMITTING'),
  SUBMITTINGORDER: Symbol('SUBMITTINGORDER'),
  CHECKING: Symbol('CHECKING'),
  SCHEDULING: Symbol('SCHEDULING'),
  ERROR: Symbol('ERROR'),
  SUCCESS: Symbol('SUCCESS'),
}

function schedule(callback: () => void, delay = 2000) {
  const timeout = setTimeout(callback, delay)
  return () => {
    clearTimeout(timeout)
  }
}

export default function ScreenFour({
  lang,
  accountForm,
  form,
  cart,
  resetState,
  nextStep,
  prevStep,
}: Props) {
  const [state, setState] = useState(STATES.SUBMITTING)
  const startDateRef = useRef(new Date().getTime())
  const userIdRef = useRef(form.userId)
  const formIdRef = useRef(form.formId)
  const query = useQueryContext()

  const cancelRequestTokenRef = useRef(axios.CancelToken.source())
  useEffect(() => {
    // Cancel all requests on unmount
    return () => cancelRequestTokenRef.current.cancel()
  }, [])

  useEffect(() => {
    // Reset state if server recieved form and user closes tab
    // If no error and form already submitted, but status not "success" yet:
    if (![STATES.ERROR, STATES.SUBMITTING].includes(state)) {
      window.addEventListener('unload', resetState)
      return () => {
        window.removeEventListener('unload', resetState)
      }
    }
  }, [state])

  useEffect(() => {
    // Check if we not waiting to long
    const tooLong = startDateRef.current + 60000 < new Date().getTime()
    if (tooLong) {
      console.error('Waiting too long')
      return setState(STATES.ERROR)
    }
    // Update userId and formId if they changed
    if (form.formId && form.userId) {
      userIdRef.current = form.userId
      formIdRef.current = form.formId
    }
    const userId = userIdRef.current
    const formId = formIdRef.current

    const cancelToken = cancelRequestTokenRef.current.token

    function onSchedule() {
      setState(STATES.CHECKING)
    }

    function onResponse(status: string) {
      switch (status) {
        case ERROR:
          return setState(STATES.ERROR)
        case SUBMITTINGORDER:
          return setState(STATES.SUBMITTINGORDER)
        case SUCCESS:
          return setState(STATES.SUCCESS)
        default:
          return setState(STATES.SCHEDULING)
      }
    }

    switch (state) {
      case STATES.SUBMITTING:
        request({
          form,
          userId,
          formId,
          cancelToken,
          onResponse,
          type: 'SUBMIT',
          query,
          cart,
        })
        return
      case STATES.SUBMITTINGORDER:
        if (form.iframeCountry === 'kz') {
          requestOrder({
            form,
            cart,
            onResponse,
          })
        } else {
          onResponse(SUCCESS)
        }
        return
      case STATES.CHECKING:
        request({
          form,
          userId,
          formId,
          cancelToken,
          onResponse,
          type: 'CHECK',
          query,
          cart,
        })
        return
      case STATES.SCHEDULING:
        return schedule(onSchedule)
      case STATES.SUCCESS:
        return nextStep()
      case STATES.ERROR:
        return
      default:
        console.error(`Unexpected state:`, state)
        return setState(STATES.ERROR)
    }
  }, [state, form, startDateRef.current])

  return (
    <main className={styles.main}>
      {state === STATES.ERROR ? (
        <ErrorScreen accountForm={accountForm} lang={lang} onError={prevStep} />
      ) : (
        <LoadingScreen></LoadingScreen>
      )}
    </main>
  )
}
