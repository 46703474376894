import React, { useEffect, useRef, useState } from 'react'
import { Calendar } from 'react-date-range'
import { useOutsideClick } from '../../utils/useOutsideClick'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateInputProps, ContentInput } from '../../types/common-types'
import ru from 'date-fns/locale/ru'
import kk from "date-fns/locale/kk";
import en from "date-fns/locale/en-US"

import {
  generateId,
  fromISOdate,
  getDaysInMonth,
  zeroPaddedNumber,
} from '../../utils/helpers'
import dayjs from 'dayjs'

import styles from '../Inputs/DatePicker.module.scss'

function getValue(value: string | undefined): string {
  if (typeof value === 'string') {
    // if '--' or '2009--' or '-23-' or '2009--09' ish
    if (value.match(/^(\d{4})?-(\d{2})?-(\d{2})?$/g)) {
      return value
    }
  }
  return '--'
}

function DatePicker({
  lang,
  accountForm,
  accountFormKey,
  onChange,
  value,
  curr,
  min,
  max,
  className,
  valid,
  required,
  ...props
}: DateInputProps) {
  // idRef is used for label tag  ("htmlFor")
  const idRef = useRef(generateId(16))
  const invalidClassName = valid ? '' : styles.invalid
  const prefixClassName = className || ''

  const [CalendarValue, setCalendarValue] = useState(
    value
      ? value?.slice(8, 10) +
          '.' +
          value?.slice(5, 7) +
          '.' +
          value?.slice(0, 4)
      : ''
  )
  const [OpenCalendar, setOpenCalendar] = useState(false)

  const ref = useOutsideClick(() => {
    setOpenCalendar(false)
  })

  // On change handler
  const handleChange = (newValue: Date) => {
    // ----------- Put here if dayjs does not work ----------------
    // let result = ''
    // if (newValue) {
    //   let day = (newValue.getDate()) <= 9 ? '0' + newValue.getDate() : newValue.getDate()
    //   let month = (newValue.getMonth()) <= 8 ? '0' + (newValue.getMonth()+1) : newValue.getMonth() + 1
    //   result = `${newValue.getFullYear()}-${month}-${day}`
    //   console.log('result', result)
    //   setCalendarValue(`${day}.${month}.${newValue.getFullYear()}`)
    if (newValue) {
      let result = dayjs(newValue).format('YYYY-MM-DD')
      setCalendarValue(dayjs(newValue).format('DD.MM.YYYY'))
      onChange(result)
    }
  }

  return (
    <label
      htmlFor={idRef.current}
      className={`${prefixClassName} ${styles.label}`.trim()}
    >
      {required}
      <p className={styles.required}>
        {(accountForm[accountFormKey] as ContentInput).label[lang]}
      </p>
      <input
        data-test-id={props.testName}
        type="text"
        className={styles.input + ' ' + invalidClassName}
        onClick={() => {
          setOpenCalendar(true)
        }}
        value={CalendarValue}
        readOnly
        placeholder={
          (accountForm[accountFormKey] as ContentInput).placeholder[lang]
        }
      />
      {OpenCalendar && (
        <div ref={ref}>
          <Calendar
            data-test-id={props.testName}
            className={styles.Calendar}
            locale={lang === 'eng' ? en : lang === 'kz' ? kk :ru}
            onChange={(date: Date) => {
              handleChange(date)
              setOpenCalendar(false)
            }}
            shownDate={curr}
            minDate={min}
            maxDate={max}
          ></Calendar>
        </div>
      )}
      {!valid && (accountForm[accountFormKey] as ContentInput).invalidText ? (
        CalendarValue.length > 0 ? (
          (accountForm[accountFormKey] as ContentInput).invalidTextCorrect ? (
            <span
              data-test-id={props.testErrorName}
              className={styles.invalidText}
            >
              {(accountForm[accountFormKey] as any).invalidTextCorrect[lang]}
            </span>
          ) : null
        ) : (
          <span
            data-test-id={props.testErrorName}
            className={styles.invalidText}
          >
            {(accountForm[accountFormKey] as any).invalidText[lang]}
          </span>
        )
      ) : null}
    </label>
  )
}

export default DatePicker
