import { Option } from '../types/common-types'

export const socialLinksOptions: Option[] = [
  {
    value: 'ВКонтакте',
    label: {
      eng: 'VK',
      rus: 'ВКонтакте',
      kz: 'VK',
    },
  },
  {
    value: 'Instagram',
    label: {
      eng: 'Instagram',
      rus: 'Instagram',
      kz: 'Instagram',
    },
  },
  {
    value: 'WhatsApp',
    label: { eng: 'WhatsApp', rus: 'WhatsApp', kz: 'WhatsApp' },
  },
  {
    value: 'YouTube',
    label: {
      eng: 'YouTube',
      rus: 'YouTube',
      kz: 'YouTube',
    },
  },
  {
    value: 'Одноклассники',
    label: {
      eng: 'OK',
      rus: 'Одноклассники',
      kz: 'OK',
    },
  },
  {
    value: 'Facebook',
    label: {
      eng: 'Facebook',
      rus: 'Facebook',
      kz: 'Facebook',
    },
  },
  {
    value: 'Viber',
    label: {
      eng: 'Viber',
      rus: 'Viber',
      kz: 'Viber',
    },
  },
  {
    value: 'X (Twitter)',
    label: {
      eng: 'X (Twitter)',
      rus: 'X (Twitter)',
      kz: 'X (Twitter)',
    },
  },
  {
    value: 'Vimeo',
    label: {
      eng: 'Vimeo',
      rus: 'Vimeo',
      kz: 'Vimeo',
    },
  },
  {
    value: 'Skype',
    label: {
      eng: 'Skype',
      rus: 'Skype',
      kz: 'Skype',
    },
  },
]
