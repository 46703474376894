import React, { useState, useEffect, useRef } from 'react'

import { actions } from '../../state-actions-reducers'
import { Lang, ScreenProps, Country } from '../../types/common-types'
import { State, Dispatch } from '../../state-actions-reducers'

import TextInput from '../../components/Inputs/TextInput'
import PhoneInput from '../../components/Inputs/PhoneInput'
import EmailInput from '../../components/Inputs/EmailInput'
import styles from './FiscalScreenThree.module.scss'
import warningImage from '../../images/warning.svg'
import SocialLinksInput from '../../components/Inputs/SocialLinksInput'
import { smartValidateString } from '../../validate/validators'

interface Props {
  lang: Lang
  state: State
  dispatch: Dispatch
  accountForm: any
  invalidFields: string[]
  initialState: any
}
interface Props extends ScreenProps {
  allCitizenships: Country[]
}

/* THE COMPONENT */
function FiscalScreenThree({
  lang,
  state,
  dispatch,
  accountForm,
  invalidFields,
  allCitizenships,
  initialState,
}: Props) {
  return (
    <>
      <main className={styles.main}>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3>{accountForm.stepTwoContactsTitle[lang]}</h3>
            </div>
            <div className="col-4 col-xs-12">
              {/* F_ContactPhoneNumber */}
              <PhoneInput
                testName="contactPhones"
                testErrorName="error-contactPhones"
                label={accountForm.contactPersonPhone.label[lang]}
                lang={lang}
                numbers={
                  state.contactPhones.length === 0 ? [''] : state.contactPhones
                }
                setNumbers={contactPhones => {
                  if (!state.Confirm_phone_ok) {
                    dispatch({
                      type: actions.CONTACT_PHONE,
                      data: contactPhones,
                    })
                  }
                  if (state.copyContactPersonEmailPhone)
                    dispatch({
                      type: actions.CONTACT_PERSON_PHONE,
                      data: contactPhones,
                    })
                }}
                required
                firstlocked={state.Confirm_phone_ok}
                valid={!invalidFields.includes('contactPhones')}
                invalidText={accountForm.contactPersonPhone.invalidText[lang]}
              />
            </div>
            <div className="col-4 col-xs-12">
              <PhoneInput
                testName="contactPhoneSeconds"
                testErrorName="error-contactPhoneSeconds"
                label={accountForm.additionalPhoneNumbers.label[lang]}
                lang={lang}
                numbers={
                  state.contactPhoneSeconds.length === 0
                    ? ['']
                    : state.contactPhoneSeconds
                }
                setNumbers={data => {
                  dispatch({
                    type: actions.CONTACT_PHONE_SECOND,
                    data,
                  })
                  if (!state.Confirm_phone_ok)
                    //&& !accountForm.Confirm_phone)
                    dispatch({
                      type: actions.Confirm_phone,
                      data: data[0] || '',
                    })
                }}
                valid={!invalidFields.includes('contactPhoneSeconds')}
              />
            </div>
            <div className="col-4 col-xs-12">
              <EmailInput
                testName={'contactEmails'}
                testErrorName={'error-contactEmails'}
                emails={
                  state.contactEmails.length === 0 ? [''] : state.contactEmails
                }
                setEmails={contactEmail => {
                  dispatch({
                    type: actions.CONTACT_EMAIL,
                    data: contactEmail,
                  })
                  if (!state.Confirm_mail_ok)
                    //&& !accountForm.Confirm_mail)
                    dispatch({
                      type: actions.Confirm_mail,
                      data: contactEmail || '',
                    })
                  if (state.copyContactPersonEmailPhone)
                    dispatch({
                      type: actions.CONTACT_PERSON_EMAIL,
                      data: contactEmail,
                    })
                }}
                placeholder={accountForm.email.placeholder[lang]}
                accountForm={accountForm}
                lang={lang}
                required
                invalidText={accountForm.email.invalidText[lang]}
                valid={!invalidFields.includes('contactEmails')}
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* TELEGRAM */}
              <TextInput
                testName={'telegram'}
                testErrorName={'error-telegram'}
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.telegram}
                onChange={telegram => {
                  telegram = telegram?.replace(/[^A-Za-z0-9_]/gi, '')
                  dispatch({
                    type: actions.TELEGRAM,
                    data: telegram.length > 0 ? ['@' + telegram] : [telegram],
                  })
                }}
                value={state.telegram[0]}
                valid={!invalidFields.includes('telegram')}
                required
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* PERSONAL WEBSITE */}
              <TextInput
                testName={'personalWebsite'}
                testErrorName={'error-personalWebsite'}
                lang={lang}
                content={accountForm.personalWebsite}
                value={state.personalWebsite}
                onChange={data => (
                  dispatch({
                    type: actions.PERSONAL_WEBSITE,
                    data: [data],
                  }),
                  console.log(state.personalWebsite)
                )}
                valid={!invalidFields.includes('personalWebsite')}
              />
            </div>
            <div className="col-8 col-xs-12">
              {/* PURPOSE OF LEASE */}
              <TextInput
                testName={'purposeOfLease'}
                testErrorName={'error-purposeOfLease'}
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.purposeOfLease}
                onChange={purposeOfLease =>
                  dispatch({
                    type: actions.PURPOSE_OF_LEASE,
                    data: purposeOfLease,
                  })
                }
                value={state.purposeOfLease || ''}
                valid={
                  state.purposeOfLease.length > 0
                    ? smartValidateString(state.purposeOfLease) &&
                      !invalidFields.includes('purposeOfLease')
                    : !invalidFields.includes('purposeOfLease')
                }
                required
              />
            </div>
          </div>
        </div>
        <SocialLinksInput
          lang={lang}
          state={state}
          dispatch={dispatch}
          accountForm={accountForm}
          invalidFields={invalidFields}
        />
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3>{accountForm.stepTwoContactTitle[lang]}</h3>
            </div>
            <div className={styles.checkInputBox + ' ' + 'col-12'}>
              <input
                id="copyInputContactsPerson"
                name="copyInputContactsPerson"
                type="checkBox"
                onChange={e => {
                  dispatch({
                    type: actions.COPY_CONTACT_PERSON,
                    data: !state.copyContactPerson,
                  })
                }}
                checked={state.copyContactPerson}
              />
              <label htmlFor="copyInputContactsPerson">
                {state.F_FiscalType === '1'
                  ? accountForm.fiscalIpContactsCopyBtnDesc[lang]
                  : accountForm.fiscalContactsCopyBtnDesc[lang]}
              </label>
            </div>
            <div className="col-4 col-xs-12">
              {/* CONTACT_LASTNAME */}
              <TextInput
                testName={'contactPersonLastname'}
                testErrorName={'error-contactPersonLastname'}
                disabled={!state.agreed || state.copyContactPerson}
                lang={lang}
                content={accountForm.lastName}
                onChange={contactPersonLastname =>
                  dispatch({
                    type: actions.CONTACT_PERSON_LASTNAME,
                    data: contactPersonLastname,
                  })
                }
                value={state.contactPersonLastname || ''}
                valid={
                  state.contactPersonLastname.length > 0
                    ? smartValidateString(state.contactPersonLastname) &&
                      !invalidFields.includes('contactPersonLastname')
                    : !invalidFields.includes('contactPersonLastname')
                }
                required
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* CONTACT_FIRSTNAME */}
              <TextInput
                testName={'contactPersonFirstname'}
                testErrorName={'error-contactPersonFirstname'}
                disabled={!state.agreed || state.copyContactPerson}
                lang={lang}
                content={accountForm.firstName}
                onChange={contactPersonFirstname =>
                  dispatch({
                    type: actions.CONTACT_PERSON_FIRSTNAME,
                    data: contactPersonFirstname,
                  })
                }
                value={state.contactPersonFirstname || ''}
                valid={
                  state.contactPersonFirstname.length > 0
                    ? smartValidateString(state.contactPersonFirstname) &&
                      !invalidFields.includes('contactPersonFirstname')
                    : !invalidFields.includes('contactPersonFirstname')
                }
                required
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* CONTACT_PATRONYMIC */}
              <TextInput
                testName={'contactPersonPatronymic'}
                testErrorName={'error-contactPersonPatronymic'}
                testCheckName={'contactPersonNoPatronymic'}
                disabled={
                  !state.agreed ||
                  state.noPatronymicContact ||
                  state.copyContactPerson
                }
                lang={lang}
                content={accountForm.patronymic}
                onChange={contactPersonPatronymic =>
                  dispatch({
                    type: actions.CONTACT_PERSON_PATRONYMIC,
                    data: contactPersonPatronymic,
                  })
                }
                checkLabel={accountForm.noPatronymic[lang]}
                checkValue={
                  state.noPatronymicContact ||
                  (state.copyContactPerson && state.noPatronymic)
                }
                checkChange={() => {
                  if (!state.copyContactPerson) {
                    dispatch({
                      type: actions.NO_PATRONYMIC_CONTACT,
                      data: !state.noPatronymicContact,
                    })
                    dispatch({
                      type: actions.CONTACT_PERSON_PATRONYMIC,
                      data: '',
                    })
                  }
                }}
                value={state.contactPersonPatronymic || ''}
                valid={
                  state.contactPersonPatronymic.length > 0
                    ? smartValidateString(state.contactPersonPatronymic) &&
                      !invalidFields.includes('contactPersonPatronymic')
                    : !invalidFields.includes('contactPersonPatronymic')
                }
                required
              />
            </div>
            <div className="col-4 col-xs-12">
              <PhoneInput
                testName="contactPersonPhone"
                testErrorName="error-contactPersonPhone"
                label={accountForm.contactPersonPhone.label[lang]}
                lang={lang}
                numbers={
                  state.contactPersonPhones.length === 0
                    ? ['']
                    : state.contactPersonPhones
                }
                setNumbers={data =>
                  dispatch({
                    type: actions.CONTACT_PERSON_PHONE,
                    data,
                  })
                }
                invalidText={accountForm.contactPersonPhone.invalidText[lang]}
                deleteText={accountForm.removeFieldButton[lang]}
                addField={accountForm.addFieldButton[lang]}
                valid={!invalidFields.includes('contactPersonPhones')}
                required
                disabled={state.copyContactPersonEmailPhone}
              />
              <div className={styles.checkInputBox}>
                <input
                  id="copyInputContacts"
                  name="copyInputContacts"
                  type="checkBox"
                  onChange={e => {
                    dispatch({
                      type: actions.COPY_CONTACT_EMAIL_PHONE,
                      data: !state.copyContactPersonEmailPhone,
                    })
                  }}
                  checked={state.copyContactPersonEmailPhone}
                />
                <label htmlFor="copyInputContacts">
                  {accountForm.fiscalContactsPersonCopyBtnDesc[lang]}
                </label>
              </div>
            </div>
            {/* CONTACT_EMAIL */}
            <div className="col-4 col-xs-12">
              <EmailInput
                testName={'contactPersonEmails'}
                testErrorName={'error-contactPersonEmails'}
                emails={
                  state.contactPersonEmails.length === 0
                    ? ['']
                    : state.contactPersonEmails
                }
                setEmails={contactPersonEmails => {
                  dispatch({
                    type: actions.CONTACT_PERSON_EMAIL,
                    data: contactPersonEmails,
                  })
                  if (!state.Confirm_mail_ok)
                    //&& !accountForm.Confirm_mail)
                    dispatch({
                      type: actions.Confirm_mail,
                      data: contactPersonEmails[0] || '',
                    })
                }}
                placeholder={accountForm.email.placeholder[lang]}
                invalidText={accountForm.email.invalidText[lang]}
                accountForm={accountForm}
                lang={lang}
                required
                valid={!invalidFields.includes('contactPersonEmails')}
                disabled={state.copyContactPersonEmailPhone}
              />
            </div>
            <div className="col-4 col-xs-12">
              {/* CONTACT_POSITION */}
              <TextInput
                testName={'contactPersonPosition'}
                testErrorName={'error-contactPersonPosition'}
                disabled={!state.agreed}
                lang={lang}
                content={accountForm.F_Contact}
                onChange={contactPersonPosition =>
                  dispatch({
                    type: actions.CONTACT_PERSON_POSITION,
                    data: contactPersonPosition,
                  })
                }
                value={state.contactPersonPosition || ''}
                valid={
                  state.contactPersonPosition.length > 0
                    ? smartValidateString(state.contactPersonPosition) &&
                      !invalidFields.includes('contactPersonPosition')
                    : !invalidFields.includes('contactPersonPosition')
                }
                required
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3>{accountForm.stepTwoRecomendation[lang]}</h3>
            </div>
            <div className="col-8 col-xs-12">
              <TextInput
                testName={'recommendations'}
                testErrorName={'error-recommendations'}
                lang={lang}
                content={accountForm.recommendations}
                value={state.recommendations}
                onChange={data =>
                  dispatch({
                    type: actions.RECOMMENDATIONS,
                    data,
                  })
                }
                valid={!invalidFields.includes('recommendations')}
                required
              />
            </div>
            <div className="col-12 col-xs-12">
              <p className={styles.warningMessage}>
                <img src={warningImage} alt="warningImage"></img>
                {accountForm.stepRecomendationWarning[lang]}
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default FiscalScreenThree
