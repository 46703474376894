import { useEffect } from 'react'
import { request } from '../utils/helpers'

import { LOCAL_STORAGE_PRODUCTS_KEYS } from '../constant'
import { API_URL, BACKEND_URL, PUBLIC_URL } from '../server-parameters'
import { ObjectValueNode } from 'graphql'
import { getSavedOrderId } from '../local-storage'
import { State } from '../state-actions-reducers'

// const headers = new Headers({
// 	'content-type': 'application/json',
// 	accept: 'text/plain'
// })

interface UseProductsCallback {
  ({
    categories,
    catalogItems,
  }: {
    categories: Array<any>
    catalogItems: Array<any>
  }): void
}

export async function getProducts(callback: UseProductsCallback) {
  const headers = new Headers({
    'content-type': 'application/json',
    accept: 'text/plain',
  })
  if (!headers.has('authorization')) {
    const token = window.localStorage.FORM_ID
    headers.set('Authorization', 'Bearer ' + token)
  }

  const fullUrl = BACKEND_URL + '/anketa/catalog '
  const fetchOptions = {
    headers,
    method: 'Get',
  }
  let response = await fetch(fullUrl, fetchOptions)

  const body = await response.json()
  //console.log(body)
  callback(body)
}

export async function getProduct(guid:string, state:State) {
  const headers = new Headers({
    'content-type': 'application/json',
    accept: 'text/plain',
  })
  if (!headers.has('authorization')) {
    const token = state.orderId
    headers.set('Authorization', 'Bearer ' + token)
  }

  const fullUrl = PUBLIC_URL + '/devices/' + guid
  const fetchOptions = {
    headers,
    method: 'Get',
  }
  let response = await fetch(fullUrl, fetchOptions)

  const body = await response.json()
  //console.log(body)
  return body.commodity
}
