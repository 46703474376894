import { BACKEND_URL } from '../server-parameters'

export async function getBanks(bik: string) {
  const headers = new Headers({
    'content-type': 'application/json',
    accept: 'text/plain',
  })
  if (!headers.has('authorization')) {
    const token = window.localStorage.FORM_ID
    headers.set('Authorization', 'Bearer ' + token)
  }

  const fullUrl = BACKEND_URL + '/anketa/BankByBik?bik=' + bik

  const fetchOptions = {
    headers,
    method: 'POST',
  }
  let response = await fetch(fullUrl, fetchOptions)
  const body = await response.json()
  //console.log(body)
  return body
}
export async function getBanksKz(bik: string) {
  const headers = new Headers({
    'content-type': 'application/json',
    accept: 'text/plain',
  })
  if (!headers.has('authorization')) {
    const token = window.localStorage.FORM_ID
    headers.set('Authorization', 'Bearer ' + token)
  }

  const fullUrl = BACKEND_URL + '/anketa/kzBankbybik?bik=' + bik

  const fetchOptions = {
    headers,
    method: 'POST',
  }
  let response = await fetch(fullUrl, fetchOptions)
  const body = await response.json()
  //console.log(body)
  return body
}
