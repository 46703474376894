import React from 'react'

import './AgreementContent.scss'

interface Props {
  html: string
}

export default function AgreementContent({ html }: Props) {
  return (
    <div className="agreement-content-container">
      <div
        className="agreement-content-text"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  )
}
