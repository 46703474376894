import { validate as emailValidator } from 'email-validator'
import { parseDate, yearsAgo } from '../../../utils/helpers'
import {
  State,
  RUSSIAN_PASSPORT,
  profileType_Fiscal,
} from '../../../state-actions-reducers'
import { allFiscalCountriesRf } from '../../../content/countries'
import { Option } from '../../../types/common-types'

interface VlidateDateArgs {
  current: Date | string
  min?: Date
  max?: Date
  required?: boolean
}

export function validateDate({
  current,
  min,
  max,
  required,
}: VlidateDateArgs): boolean {
  // By default allow empty strings. Make stronger checking on submitting
  const currentValue = parseDate(current)
  if (!currentValue) {
    return !required
  }
  if (min && max) {
    return min <= currentValue && currentValue <= max
  }
  if (min) {
    return min <= currentValue
  }
  if (max) {
    return currentValue <= max
  }
  return true
}
export function smartValidateString(string: string): boolean {
  const trimmedString = string.trim()
  const letter = trimmedString.match(/[a-zA-Zа-яёА-ЯЁ]/g)?.length ?? 0
  const nums = trimmedString.match(/[0-9]/g)?.length ?? 0
  const symbols =
    trimmedString.match(
      /[\<\>\@\!\#\$\%\^\&\*\(\)\_\+\[\]\{\}\?\:\;\|\'\"\,\.\/\~\`\\\-\=\']/g
    )?.length ?? 0
  return (
    /[a-zA-Zа-яёА-ЯЁ]/g.test(trimmedString) &&
    symbols <= letter * 2 &&
    nums <= letter * 4
  )
}
export function validateDatesRange(from: Date | string, to: Date | string) {
  const smallerDate = parseDate(from)
  const biggerDate = parseDate(to)
  if (!smallerDate || !biggerDate) {
    return false
  }
  return smallerDate < biggerDate
}

export function validateCollegeDates(
  startDate: string | Date,
  endDate: string | Date,
  required = false
) {
  if (!startDate || !endDate) return !required
  // Check if dates is valid
  return validateDatesRange(startDate, endDate)
}

// By default allow empty strings
// Will make stronger validation on submitting
export function validateString(
  string: string,
  maxLength: number,
  required?: boolean,
  regexp?: RegExp
): boolean {
  const minLength = required ? 1 : 0
  const trimmedString = string.trim()
  return (
    trimmedString.length >= minLength &&
    trimmedString.length <= maxLength &&
    Boolean(trimmedString.match(regexp || ''))
  )
}

export function validateAddress(name: string, required?: boolean): boolean {
  return validateString(name, 200, required)
}

export function validateBankAccount(name: string, required?: boolean): boolean {
  return validateString(name, 200, required)
}

export function validateName(name: string, required?: boolean): boolean {
  return validateString(name, 200, required)
}

export function birthPlace(place: string, required?: boolean): boolean {
  return validateString(place, 300, required)
}

export function stringDigitsLength(
  digits: string,
  digitslength: number,
  required = true
): boolean {
  return (
    validateString(digits, 100, false) &&
    ((required && digits.length == digitslength) || !required)
  )
}

export function passportDigits(digits: string, required?: boolean): boolean {
  return validateString(digits, 100, required)
}

export function passportText(text: string, required?: boolean): boolean {
  return validateString(text, 400, required)
}

export function validateEmail(text: string, required?: boolean): boolean {
  if (!text && !required) return true
  return validateString(text, 100, required) && emailValidator(text)
}

export function validateReferences(
  values: string[],
  required = false
): boolean {
  if (!required && values.length === 0) return true
  if (required && values.length === 0) return false
  if (values.length < 20) {
    return values.every(value => {
      return validateString(value, 200)
    })
  }
  return false
}

const URL_REGEXP = /^((http(s){0,1}:\/\/(\w|\d)+){1}|\w+(\w|\d)*\.\w)[^\s]*((\w|\d)\.(\w|\d)[^\s]*)*(\w|\d|\/)$/g
export function validateUrl(
  url: string,
  maxLength = 300,
  required = false
): boolean {
  if (!url && !required) return true
  if (url) {
    if (typeof url === 'string') {
      if (url.length < maxLength) {
        return Boolean(url.match(URL_REGEXP))
      }
    }
  }
  return false
}

export function validateSocialLink(value: string, required = false): boolean {
  return validateUrl(value, 300, required)
  return false
}

export function validatePhone(value: string): boolean {
  return validateString(value, 24, true) && value.length >= 11
}

export function validatePassportNumber(
  value: string,
  required = false,
  minLength?: number,
  maxLength?: number
) {
  return (
    validateString(value, maxLength || 10, required) &&
    value.replace(/[^a-zA-Zа-яА-Я0-9 ]/g, '').length >= (minLength || 7)
  )
}

export function validateStepCompanyRus(step: number, state: State): string[] {
  let selectedCitizenship, countryRegistration
  allFiscalCountriesRf.map((country: Option) => {
    if (country.value === state.citizenship)
      selectedCitizenship = country.label.rus
    if (country.value === state.countryRegistration)
      countryRegistration = country.label.rus
  })
  switch (step) {
    case 1:
      return [
        // // Array of invalid field names
        // // validate(): boolean && 'field name' -> null or 'field name'
        // // Then this array filtered with the Boolean()
        // !validateString(state.mainCity, 100, true) && 'mainCity',
        !validateName(state.F_FiscalType, true) && 'F_FiscalType',
        !validateName(state.headFirstname, true) && 'headFirstname',
        !validateName(state.headLastname, true) && 'headLastname',
        !validateName(state.headPatronymic, true) &&
          !state.noPatronymic &&
          'headPatronymic',
        !validateDate({
          current: state.registeredDate,
          required: true,
        }) && 'registeredDate',
        !stringDigitsLength(state.F_INN, 10) && 'F_INN',
        !stringDigitsLength(state.F_KPP, 9) && 'F_KPP',
        // state.F_INN_Image.length === 0 && 'F_INN_Image',
        !stringDigitsLength(state.F_OGRN, 13) && 'F_OGRN',
        // state.F_OGRN_Image.length === 0 && 'F_OGRN_Image',
        state.F_EGRUL_Image.length === 0 && 'F_EGRUL_Image',
        state.F_Rent_Contract_Image.length === 0 &&
          !state.noLeaseAgreement &&
          'F_Rent_Contract_Image',
        !validateString(state.addressFactualCountry, 300, true) &&
          'addressFactualCountry',
        !validateString(state.addressFactualRegion, 300, true) &&
          'addressFactualRegion',
        !validateString(state.addressFactualCity, 300, true) &&
          'addressFactualCity',
        !validateString(state.addressFactualStreet, 300, true) &&
          'addressFactualStreet',
        !validateString(state.addressFactualBuilding, 300, true) &&
          'addressFactualBuilding',
        !validateString(state.addressFactualApartments, 300, true) &&
          !state.addressFactualNoApartments &&
          'addressFactualApartments',
        !validateString(state.addressRegisteredPostCode, 50, true) &&
          'addressRegisteredPostCode',
        !validateString(state.addressRegisteredCountry, 300, true) &&
          'addressRegisteredCountry',
        !validateString(state.addressRegisteredRegion, 300, true) &&
          'addressRegisteredRegion',
        !validateString(state.addressRegisteredCity, 300, true) &&
          'addressRegisteredCity',
        !validateString(state.addressRegisteredStreet, 300, true) &&
          'addressRegisteredStreet',
        !validateString(state.addressRegisteredBuilding, 300, true) &&
          'addressRegisteredBuilding',
        !validateString(state.addressRegisteredApartments, 300, true) &&
          !state.addressRegisteredNoApartments &&
          'addressRegisteredApartments',
        !validateString(state.addressFactualPostCode, 50, true) &&
          'addressFactualPostCode',
        !validateName(state.headPosition, true) && 'headPosition',
        !validateName(state.headPositionGenitive, true) &&
          'headPositionGenitive',
        +state.foundingDocument === 0 && 'foundingDocument',
        state.foundingDocument_Image.length === 0 && 'foundingDocument_Image',
        !validateName(state.headFirstnameGenitive, true) &&
          'headFirstnameGenitive',
        !validateName(state.headLastnameGenitive, true) &&
          'headLastnameGenitive',
        !validateName(state.headPatronymicGenitive, true) &&
          !state.noPatronymic &&
          !state.noPatronymic &&
          'headPatronymicGenitive',
        state.F_Scan_Directors_Passport.length === 0 &&
          'F_Scan_Directors_Passport',
        state.F_Scan_Directors_Passport_Address.length === 0 &&
          'F_Scan_Directors_Passport_Address',
        !validateName(state.F_FullName, true) && 'F_FullName',
        !validateName(state.F_ShortName, true) && 'F_ShortName',
      ].filter(Boolean) as string[]
    case 2:
      return [
        ...state.BankDetails.map((bank, i) => {
          if (bank.F_BankRS.length < 12) return `F_BankRS${i}`
        }),
        ...state.BankDetails.map((bank, i) => {
          if (bank.F_BankRS.length < 12) return `F_BankKS${i}`
        }),
        ...state.BankDetails.map((bank, i) => {
          if (bank.F_BankBIK.length < 8) return `F_BankBIK${i}`
        }),
        ...state.BankDetails.map((bank, i) => {
          if (!smartValidateString(bank.F_BankName)) return `F_BankName${i}`
        }),
      ].filter(Boolean) as string[]
    case 3:
      return [
        state?.contactPhoneSeconds?.[0]?.length > 0 &&
          !state?.contactPhoneSeconds?.some(number => validatePhone(number)) &&
          'contactPhoneSeconds',
        state?.telegram[0]?.replace(/[^A-Za-z0-9@_]/g, '').length > 0 &&
          (state?.telegram[0]?.replace(/[^A-Za-z0-9_]/g, '').length < 5 ||
            (state?.telegram[0]?.match(/[a-zA-Zа-яёА-ЯЁ]/g) || '')?.length <
              1) &&
          'telegram',
        state?.personalWebsite[0]?.length > 0 &&
          !validateUrl(state?.personalWebsite[0], 300, true) &&
          'personalWebsite',
        !state.contactPhones.some(number => validatePhone(number)) &&
          'contactPhones',
        !state.contactEmails.some(contactEmails =>
          validateEmail(contactEmails, true)
        ) && 'contactEmails',
        !smartValidateString(state.purposeOfLease) && 'purposeOfLease',
        !state.additionalLinksDescription.some(desc =>
          validateString(desc, 300, true)
        ) && 'additionalLinksDescription',
        !state.additionalLinks.some(links =>
          validateString(links, 200, true)
        ) && 'additionalLinks',
        !smartValidateString(state.contactPersonFirstname) &&
          'contactPersonFirstname',
        !smartValidateString(state.contactPersonLastname) &&
          'contactPersonLastname',
        !smartValidateString(state.contactPersonPatronymic) &&
          !state.noPatronymicContact &&
          'contactPersonPatronymic',
        !state.contactPersonPhones.some(number => validatePhone(number)) &&
          'contactPersonPhones',
        !state.contactPersonEmails.some(contactPersonEmails =>
          validateEmail(contactPersonEmails, true)
        ) && 'contactPersonEmails',
        !smartValidateString(state.contactPersonPosition) &&
          'contactPersonPosition',
      ].filter(Boolean) as string[]
    case 4:

    default:
      return []
  }
}

export function validateForm(state: State): string[] {
  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].reduce(
    (result: string[], step: number): string[] => [
      ...result,
      ...validateStepCompanyRus(step, state),
    ],
    []
  )
}

export function trimForm(state: State): State {
  return Object.keys(state).reduce((trimmedState: State, key) => {
    switch (key) {
      case 'mobilePhoneNumbers':
      case 'F_WorkPhoneNumber':
      case 'contactPhones':
      case 'addressRegisteredPhones':
      case 'addressFactualPhones':
      case 'workPhoneNumbers':
        // Filter out invalid values
        return {
          ...trimmedState,
          [key]: state[key].filter(validatePhone),
        }
      case 'emails':
        return {
          ...trimmedState,
          emails: state.emails.filter(email => validateEmail(email, true)),
        }
      case 'F_Email':
        return {
          ...trimmedState,
          emails: state.emails.filter(email => validateEmail(email, true)),
        }
      case 'vk':
      case 'facebook':
      case 'youtube':
      case 'vimeo':
      case 'instagram':
      case 'workWebsite':
      case 'personalWebsite':
      case 'additionalLinks':
      case 'addressRegisteredDate':
        return {
          ...trimmedState,
          addressRegisteredDate: validateDate({
            current: state.addressRegisteredDate,
            max: new Date(),
          })
            ? state.addressRegisteredDate
            : '',
        }
      case 'isStudent':
        return {
          ...trimmedState,
          collegeStartedAt: state.isStudent ? state.collegeStartedAt : '',
          collegeEndedAt: state.isStudent ? state.collegeEndedAt : '',
        }
      default:
        return trimmedState
    }
  }, state)
}
