import React from 'react'

import styles from './LoadingScreen.module.scss'
import LoaderImg from '../images/loader.svg'

const LoadingScreen: React.FC = ({ children }) => (
  <div className={styles.LoadContainer}>
    <img src={LoaderImg} alt="loader" />
  </div>
)

export default LoadingScreen
