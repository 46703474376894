import React from 'react'
import deleteButtonImage from '../../images/deleteButton.svg'

interface Props {
  className?: string
  onClick: () => void
  testName?: string
}
export default function DeleteButton({ className, onClick, testName }: Props) {
  return (
    <img
      data-test-id={testName}
      className={className}
      onClick={onClick}
      src={deleteButtonImage}
      alt="deleteButtonImage"
      width="18"
      height="18"
    />
  )
}
