import React, { useRef, useEffect } from 'react'

import { TextInputProps } from '../../types/common-types'
import { generateId } from '../../utils/helpers'
import InputMask from 'react-input-mask'

import styles from '../Inputs/TextInput.module.scss'

function TextInput({
  disabled,
  lang,
  content,
  onChange,
  checkLabel,
  checkValue,
  checkChange,
  value,
  className,
  valid,
  required,
  focus,
  onBlur,
  mask,
  onKeyDown,
  ...props
}: TextInputProps) {
  const idRef = useRef(generateId(16))
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focus])
  return (
    <label
      htmlFor={idRef.current}
      className={`${className || ''} ${styles.label}`.trim()}
    >
      {content.label[lang].length > 0 ? (
        <p className={styles.labelText}>
          {content.label[lang]}
          {required && <span className={styles.required}></span>}
          {checkLabel && checkChange ? (
            <div className={styles.checkChangeBox}>
              <input
                data-test-id={props.testCheckName}
                id={`hideInput-${idRef.current}`}
                name={`hideInput-${idRef.current}`}
                type="checkBox"
                onChange={checkChange}
                checked={checkValue}
              />
              <label htmlFor={`hideInput-${idRef.current}`}>{checkLabel}</label>
            </div>
          ) : null}
        </p>
      ) : null}
      <InputMask
        data-test-id={props.testName}
        id={idRef.current}
        disabled={disabled}
        className={`${styles.input} ${valid ? '' : styles.invalid}`}
        type="text"
        placeholder={content.placeholder[lang]}
        onKeyDown={onKeyDown}
        onChange={event => {
          if (event.target) {
            const validText =
              lang === 'eng' && !mask
                ? event.target.value.replace(/[^A-Za-z0-9/\D/]/gi, '')
                : lang === 'rus' && !mask
                ? event.target.value.replace(/[^a-zA-Zа-яёА-ЯЁ0-9/\D/]/gi, '')
                : event.target.value
            onChange(validText as string, event)
          }
        }}
        value={value}
        onBlur={onBlur}
        mask={mask || ''}
      />
      {}
      {!valid && content.invalidText ? (
        <span className={styles.invalidText} data-test-id={props.testErrorName}>
          {content.invalidText[lang]}
        </span>
      ) : null}
    </label>
  )
}

export default TextInput
