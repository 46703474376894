// TODO: Handle server errors. Now user can't proceed if server responded with
// error

import React from 'react'

import { Lang } from '../../types/common-types'
import { State, Dispatch, actions } from '../../state-actions-reducers'
import AccountForm from '../../types/account-form.types'
import ProductsInput from '../../components/Inputs/ProductsInput'
import styles from './ScreenThree.module.scss'

interface Props {
  lang: Lang
  state: State
  dispatch: Dispatch
  accountForm: AccountForm
  invalidFields: string[]
}

/* THE COMPONENT */
function ScreenThree({
  lang,
  state,
  dispatch,
  accountForm,
  invalidFields,
}: Props) {
  return (
    <>
      <main className={styles.main}>
        <div className="form-group">
          <div className="row">
            <div className="col-12 col-xs-12">
              <h3>{accountForm.stepThreeHeader[lang]}</h3>
              <p>{accountForm.stepThreeHeaderDesc[lang]}</p>
            </div>
          </div>
          <ProductsInput
            state={state}
            lang={lang}
            accountForm={accountForm}
            initialValue={state.goods}
            dispatch={dispatch}
            onChange={data => {
              dispatch({
                type: actions.GOODS,
                data,
              })
            }}
            valid={true}
            required
          />
        </div>
      </main>
    </>
  )
}

export default ScreenThree
