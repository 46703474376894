export const additionalDocumentTypeValues = [
  {
    value: '2',
    label: {
      eng: 'Oud. personalities',
      rus: 'Уд. личности',
      kz: 'Оуд. тұлғалар',
    },
  },
  {
    value: '3',
    label: {
      eng: 'international passport',
      rus: 'Загранпаспорт',
      kz: 'халықаралық төлқұжат',
    },
  },
  {
    value: '5',
    label: {
      eng: 'INN',
      rus: 'ИНН',
      kz: 'ҚАЛАЙЫ',
    },
  },
  {
    value: '6',
    label: {
      eng: 'Military ID',
      rus: 'Военный билет',
      kz: 'Әскери билет',
    },
  },
  {
    value: '7',
    label: {
      eng: "Driver's license",
      rus: 'Водительское удостоверение',
      kz: 'Жүргізуші куәлігі',
    },
  },
  {
    value: '8',
    label: {
      eng: 'ID-card',
      rus: 'ID карта',
      kz: 'Жеке куәлік',
    },
  },
  {
    value: '9',
    label: {
      eng: 'Student ID',
      rus: 'Студенческий билет',
      kz: 'Студенттік ID',
    },
  },
  {
    value: '10',
    label: {
      eng: 'Diploma',
      rus: 'Диплом',
      kz: 'Диплом',
    },
  },
  {
    value: '11',
    label: {
      eng: 'Temp. residence permit',
      rus: 'РВП',
      kz: 'Уақытша тұруға рұқсат ',
    },
  },
  {
    value: '12',
    label: {
      eng: 'Residence permit of another country',
      rus: 'ВНЖ другой страны',
      kz: 'Басқа елдің тұруға рұқсаты',
    },
  },
]

export const additionalDocumentTypeValuesKz = [
  {
    value: '1',
    label: {
      eng: 'Biometric passport',
      rus: 'Биометрический паспорт',
      kz: 'Биометриялық төлқұжат',
    },
  },
  {
    value: '12',
    label: {
      eng: 'Residence permit of another country',
      rus: 'ВНЖ другой страны',
      kz: 'Басқа елдің тұруға рұқсаты',
    },
  },
  {
    value: '7',
    label: {
      eng: "Driver's license",
      rus: 'Водительское удостоверение',
      kz: 'Жүргізуші куәлігі',
    },
  },
  {
    value: '10',
    label: {
      eng: 'Diploma',
      rus: 'Диплом',
      kz: 'Диплом',
    },
  },
  {
    value: '9',
    label: {
      eng: 'Student ID',
      rus: 'Студенческий билет',
      kz: 'Студенттік ID',
    },
  },
]

export const additionalDocumentTypeValuesKzForRf = [
  {
    value: '2',
    label: {
      eng: 'Oud. personalities',
      rus: 'Уд. личности',
      kz: 'Оуд. тұлғалар',
    },
  },
  {
    value: '11',
    label: {
      eng: 'Temp. residence permit',
      rus: 'РВП',
      kz: 'Уақытша тұруға рұқсат ',
    },
  },
  {
    value: '7',
    label: {
      eng: "Driver's license",
      rus: 'Водительское удостоверение',
      kz: 'Жүргізуші куәлігі',
    },
  },
]

export const additionalDocumentTypeValuesRfForKz = [
  {
    value: '1',
    label: {
      eng: 'Russian passport',
      rus: 'Внутренний паспорт РФ',
      kz: 'Ресей паспорты',
    },
  },
  {
    value: '12',
    label: {
      eng: 'Residence permit of another country',
      rus: 'ВНЖ другой страны',
      kz: 'Басқа елдің тұруға рұқсаты',
    },
  },
  {
    value: '11',
    label: {
      eng: 'Temp. residence permit',
      rus: 'РВП',
      kz: 'Уақытша тұруға рұқсат ',
    },
  },
  {
    value: '7',
    label: {
      eng: "Driver's license",
      rus: 'Водительское удостоверение',
      kz: 'Жүргізуші куәлігі',
    },
  },
  {
    value: '10',
    label: {
      eng: 'Diploma',
      rus: 'Диплом',
      kz: 'Диплом',
    },
  },
  {
    value: '9',
    label: {
      eng: 'Student ID',
      rus: 'Студенческий билет',
      kz: 'Студенттік ID',
    },
  },
]

export const additionalDocumentTypeValuesRf = [
  {
    value: '3',
    label: {
      eng: 'international passport',
      rus: 'Загранпаспорт',
      kz: 'халықаралық төлқұжат',
    },
  },
  {
    value: '7',
    label: {
      eng: "Driver's license",
      rus: 'Водительское удостоверение',
      kz: 'Жүргізуші куәлігі',
    },
  },
  {
    value: '10',
    label: {
      eng: 'Diploma',
      rus: 'Диплом',
      kz: 'Диплом',
    },
  },
  {
    value: '6',
    label: {
      eng: 'Military ID',
      rus: 'Военный билет',
      kz: 'Әскери билет',
    },
  },
  {
    value: '9',
    label: {
      eng: 'Student ID',
      rus: 'Студенческий билет',
      kz: 'Студенттік ID',
    },
  },
]

export const additionalDocumentTypeValuesKgz = [
  {
    value: '1',
    label: {
      eng: 'National passport',
      rus: 'Национальный паспорт ',
      kz: 'Ұлттық паспорт',
    },
  },
  {
    value: '12',
    label: {
      eng: 'Residence permit of another country',
      rus: 'ВНЖ другой страны',
      kz: 'Басқа елдің тұруға рұқсаты',
    },
  },
  {
    value: '11',
    label: {
      eng: 'Temp. residence permit',
      rus: 'РВП',
      kz: 'Уақытша тұруға рұқсат ',
    },
  },
  {
    value: '7',
    label: {
      eng: "Driver's license",
      rus: 'Водительское удостоверение',
      kz: 'Жүргізуші куәлігі',
    },
  },
  {
    value: '10',
    label: {
      eng: 'Diploma',
      rus: 'Диплом',
      kz: 'Диплом',
    },
  },
  {
    value: '9',
    label: {
      eng: 'Student ID',
      rus: 'Студенческий билет',
      kz: 'Студенттік ID',
    },
  },
]

export const additionalDocumentTypeValuesOthers = [
  {
    value: '12',
    label: {
      eng: 'Residence permit of another country',
      rus: 'ВНЖ другой страны',
      kz: 'Басқа елдің тұруға рұқсаты',
    },
  },
  {
    value: '7',
    label: {
      eng: "Driver's license",
      rus: 'Водительское удостоверение',
      kz: 'Жүргізуші куәлігі',
    },
  },
  {
    value: '10',
    label: {
      eng: 'Diploma',
      rus: 'Диплом',
      kz: 'Диплом',
    },
  },
  {
    value: '9',
    label: {
      eng: 'Student ID',
      rus: 'Студенческий билет',
      kz: 'Студенттік ID',
    },
  },
]

export const additionalDocumentTypeValuesGbr = [
  {
    value: '8',
    label: {
      eng: 'CitizenCard',
      rus: 'ID карта',
      kz: 'Жеке куәлік',
    },
  },
  {
    value: '12',
    label: {
      eng: 'Residence permit of another country',
      rus: 'ВНЖ другой страны',
      kz: 'Басқа елдің тұруға рұқсаты',
    },
  },
  {
    value: '7',
    label: {
      eng: "Driver's license",
      rus: 'Водительское удостоверение',
      kz: 'Жүргізуші куәлігі',
    },
  },
  {
    value: '10',
    label: {
      eng: 'Diploma',
      rus: 'Диплом',
      kz: 'Диплом',
    },
  },
  {
    value: '9',
    label: {
      eng: 'Student ID',
      rus: 'Студенческий билет',
      kz: 'Студенттік ID',
    },
  },
]

export const additionalDocumentTypeValuesBel = [
  {
    value: '8',
    label: {
      eng: 'CitizenCard',
      rus: 'ID карта',
      kz: 'Жеке куәлік',
    },
  },
  {
    value: '7',
    label: {
      eng: "Driver's license",
      rus: 'Водительское удостоверение',
      kz: 'Жүргізуші куәлігі',
    },
  },
  {
    value: '10',
    label: {
      eng: 'Diploma',
      rus: 'Диплом',
      kz: 'Диплом',
    },
  },
  {
    value: '9',
    label: {
      eng: 'Student ID',
      rus: 'Студенческий билет',
      kz: 'Студенттік ID',
    },
  },
]
