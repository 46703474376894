import { Option } from '../types/common-types'

export const foundingDocumentOptions: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Charter',
      rus: 'Устав',
      kz: 'Жарғы',
    },
  },
  {
    value: '4',
    label: { eng: 'Proxy', rus: 'Доверенность', kz: 'Сенімхат' },
  },
]
