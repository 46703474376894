export const getCertificateRegistrationDate = (fiscalType: string) => {
  return {
    date: {
      label: {
        eng: `${
          fiscalType === '1'
            ? 'Date of registration IP*'
            : fiscalType === '3'
            ? 'Date of registration JSC*'
            : fiscalType === '4'
            ? 'Date of registration PJSC*'
            : fiscalType === '5'
            ? 'Date of registration CJSC*'
            : fiscalType === '6'
            ? 'Date of registration LLP*'
            : 'Date of registration LLP*'
        }`,
        rus: `${
          fiscalType === '1'
            ? 'Дата регистрации ИП*'
            : fiscalType === '3'
            ? 'Дата регистрации АО*'
            : fiscalType === '4'
            ? 'Дата регистрации ПАО*'
            : fiscalType === '5'
            ? 'Дата регистрации ЗАО*'
            : fiscalType === '6'
            ? 'Дата регистрации ТОО*'
            : 'Дата регистрации ООО*'
        }`,
        kz: `${
          fiscalType === '1'
            ? 'Тіркеу күні IP*'
            : fiscalType === '3'
            ? 'Тіркеу күні АҚ*'
            : fiscalType === '4'
            ? 'Тіркеу күні ЖАҚ*'
            : fiscalType === '5'
            ? 'Тіркеу күні ЖАҚ*'
            : fiscalType === '6'
            ? 'Тіркеу күні ЖШС*'
            : 'Тіркеу күні ЖШС*'
        }`,
      },
      placeholder: {
        eng: 'dd.mm.yyyy',
        rus: 'дд.мм.гггг',
        kz: 'ГГГГ.ДД.ММ',
      },
      invalidText: {
        eng: 'Enter the registration date correctly',
        rus: 'Введите дату регистрации корректно',
        kz: 'Тіркеу күнін дұрыс енгізіңіз',
      },
    },
  }
}
