export const getFiscalFullName = (
  fiscalType: string,
  countryRegistration: string
) => {
  return {
    label: {
      eng: `${
        fiscalType === '1'
          ? 'Full name IP*'
          : fiscalType === '3'
          ? 'Full name JSC*'
          : fiscalType === '4'
          ? 'Full name PJSC*'
          : fiscalType === '5'
          ? 'Full name CJSC*'
          : fiscalType === '7'
          ? 'Full name LLP*'
          : 'Full name LLP*'
      }`,
      rus: `${
        fiscalType === '1'
          ? 'Полное наименование ИП*'
          : fiscalType === '3'
          ? 'Полное наименование АО*'
          : fiscalType === '4'
          ? 'Полное наименование ПАО*'
          : fiscalType === '5'
          ? 'Полное наименование ЗАО*'
          : fiscalType === '7' && countryRegistration === '417'
          ? 'Полное наименование ОсОО*'
          : 'Полное наименование ООО*'
      }`,
      kz: `${
        fiscalType === '1'
          ? 'Толық аты IP*'
          : fiscalType === '3'
          ? 'Толық аты АҚ*'
          : fiscalType === '4'
          ? 'Толық аты ЖАҚ*'
          : fiscalType === '5'
          ? 'Толық аты ЖАҚ*'
          : fiscalType === '7'
          ? 'Толық аты ЖШС*'
          : 'Толық аты ЖШС*'
      }`,
    },
    placeholder: {
      eng: `${
        fiscalType === '1'
          ? countryRegistration === '417'
            ? 'IP «Lastochka»'
            : 'IP «London industrial company»'
          : fiscalType === '3'
          ? countryRegistration === '417'
            ? 'JSC «Almaty Industrial Company»'
            : 'JSC «London industrial company»'
          : fiscalType === '4'
          ? countryRegistration === '417'
            ? 'PJSC «Almaty Industrial Company»'
            : 'PJSC «London industrial company»'
          : fiscalType === '5'
          ? countryRegistration === '417'
            ? 'CJSC «Almaty Industrial Company»'
            : 'CJSC «London industrial company»'
          : fiscalType === '7'
          ? countryRegistration === '417'
            ? 'LLP «Almaty Industrial Company»'
            : 'LLP «London industrial company»'
          : 'LLC «London industrial company»'
      }`,
      rus: `${
        fiscalType === '1'
          ? countryRegistration === '417'
            ? 'ИП «Ласточка»'
            : 'ИП «Московская промышленная компания»'
          : fiscalType === '3'
          ? countryRegistration === '417'
            ? 'АО «Алматинская промышленная компания»'
            : 'АО «Московская промышленная компания»'
          : fiscalType === '4'
          ? countryRegistration === '417'
            ? 'ПАО «Алматинская промышленная компания»'
            : 'ПАО «Московская промышленная компания»'
          : fiscalType === '5'
          ? countryRegistration === '417'
            ? 'ЗАО «Алматинская промышленная компания»'
            : 'ЗАО «Московская промышленная компания»'
          : fiscalType === '7' && countryRegistration === '417'
          ? 'ОсОО «Алматинская промышленная компания»'
          : 'ООО «Московская промышленная компания»'
      }`,
      kz: `${
        fiscalType === '1'
          ? countryRegistration === '417'
            ? 'IP «Martïn»'
            : 'IP «Мәскеу өнеркәсіптік компаниясы»'
          : fiscalType === '3'
          ? countryRegistration === '417'
            ? 'АҚ «Алматы өнеркәсіп кәсіпорны»'
            : 'АҚ «Мәскеу өнеркәсіптік компаниясы»'
          : fiscalType === '4'
          ? countryRegistration === '417'
            ? 'ЖАҚ «Алматы өнеркәсіп кәсіпорны»'
            : 'ЖАҚ «Мәскеу өнеркәсіптік компаниясы»'
          : fiscalType === '5'
          ? countryRegistration === '417'
            ? 'ЖАҚ «Алматы өнеркәсіп кәсіпорны»'
            : 'ЖАҚ «Мәскеу өнеркәсіптік компаниясы»'
          : fiscalType === '7'
          ? countryRegistration === '417'
            ? 'ЖШС «Алматы өнеркәсіп кәсіпорны»'
            : '(ЖШС «Мәскеу өнеркәсіптік компаниясы»'
          : 'ЖШС «Мәскеу өнеркәсіптік компаниясы»'
      }`,
    },
    invalidText: {
      eng: 'Enter the name correctly',
      rus: 'Введите наименование корректно',
      kz: 'Атын дұрыс енгізіңіз',
    },
  }
}
