import { Option } from '../types/common-types'

export const FiscalTypeValues: Option[] = [
  // {
  //   value: '1',
  //   label: {
  //     eng: 'Individual Entrepreneur (IP)',
  //     rus: 'Индивидуальный предприниматель (ИП)',
  //     kz: 'Жеке кәсіпкер (IP)',
  //   },
  // },
  {
    value: '2',
    label: {
      eng: 'Limited Liability Company (LLC)',
      rus: 'Общество с ограниченной ответственностью (ООО)',
      kz: 'Жауапкершілігі шектеулі серіктестік (ЖШС)',
    },
  },
  {
    value: '3',
    label: {
      eng: 'Joint Stock Company (JSC)',
      rus: 'Акционерное общество (АО)',
      kz: 'Акционерлік қоғам (АҚ)',
    },
  },
  {
    value: '4',
    label: {
      eng: 'Public Joint Stock Company (PJSC)',
      rus: 'Публичное акционерное общество (ПАО)',
      kz: 'Қоғамдық акционерлік қоғам (ЖАҚ)',
    },
  },
  // {
  //   value: '5',
  //   label: {
  //     eng: 'Closed Joint Stock Company (CJSC)',
  //     rus: 'Закрытое акционерное общество (ЗАО)',
  //     kz: 'Жабық акционерлік қоғам (ЖАҚ)',
  //   },
  // },
]
export const FiscalTypeValuesKz: Option[] = [
  {
    value: '3',
    label: {
      eng: 'Joint Stock Company (JSC)',
      rus: 'Акционерное общество (АО)',
      kz: 'Акционерлік қоғам (АҚ)',
    },
  },
  {
    value: '6',
    label: {
      eng: 'Limited Liability Partnership (LLP)',
      rus: 'Товарищество с ограниченной ответственностью (ТОО)',
      kz: 'Жауапкершілігі шектеулі серіктестік (ЖШС)',
    },
  },
]
export const IpTypeValuesKz: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Individual Entrepreneur (IP)',
      rus: 'Индивидуальный предприниматель (ИП)',
      kz: 'Жеке кәсіпкер (IP)',
    },
  },
]
export const FiscalTypeValuesRom: Option[] = [
  {
    value: '1',
    label: {
      eng: 'Individual Entrepreneur (IP)',
      rus: 'Индивидуальный предприниматель (ИП)',
      kz: 'Жеке кәсіпкер (IP)',
    },
  },
  {
    value: '2',
    label: {
      eng: 'Limited Liability Company (LLC)',
      rus: 'Общество с ограниченной ответственностью (ООО)',
      kz: 'Жауапкершілігі шектеулі серіктестік (ЖШС)',
    },
  },
]
export const FiscalTypeValuesKgz = [
  {
    value: '1',
    label: {
      eng: 'Individual Entrepreneur (IP)',
      rus: 'Индивидуальный предприниматель (ИП)',
      kz: 'Жеке кәсіпкер (IP)',
    },
  },
  {
    value: '7',
    label: {
      eng: 'Limited Liability Company (LLC)',
      rus: 'Общество с ограниченной ответственностью (ОсОО)',
      kz: 'Жауапкершілігі шектеулі серіктестік (ЖШС)',
    },
  },
]
export const soleTraderType = [
  {
    value: '1',
    label: {
      eng: 'IP of the old type',
      rus: 'ИП старого образца',
      kz: 'Ескі типтегі IP',
    },
  },
  {
    value: '2',
    label: {
      eng: 'IP of a new type',
      rus: 'ИП нового образца',
      kz: 'Жаңа түрдегі IP ',
    },
  },
]
