import React, { useState, useEffect, useRef } from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import AccountForm from '../../types/account-form.types'

import { useDefer } from '../../utils/helpers'
import { generateId } from '../../utils/helpers'

import 'react-phone-input-2/lib/style.css'

import styles from './PhoneInput.module.scss'
import accountForm from '../../content/account-form'

interface Props {
  numbers: string[]
  setNumbers: (s: string[]) => void
  deleteText?: string
  addField?: string
  label: string
  valid?: boolean
  required?: boolean
  firstlocked?: boolean
  invalidText?: string
  phoneCheck?: boolean
  disabled?: boolean
  [prop: string]: any
}

export default function PhoneInput({
  numbers,
  setNumbers,
  deleteText,
  addField,
  label,
  valid,
  required,
  firstlocked,
  invalidText,
  phoneCheck,
  disabled,
  lang,
  ...props
}: Props) {
  // There is always must be at least one input field
  //const [state, setState] = useState(numbers.length === 0 ? [''] : numbers)

  // Update upper state after some time on changes.
  // We manualy update state when delete field (see delete button onClick handler)
  const [inOnChangeInput, setInOnChangeInput] = useState(false)
  useDefer(
    () => {
      if (inOnChangeInput) {
        setInOnChangeInput(false)
        setNumbers(numbers)
      }
    },
    [numbers],
    300
  )
  const [deleting, setDeleting] = useState(false)
  const addFieldBtnClassName = deleting
    ? `${styles.animateDeleting} ${styles.addField}`
    : styles.addField

  const idRef = useRef(generateId(16))
  const [lastInput, setLastInput] = useState(numbers.length - 1)
  useEffect(() => {
    setLastInput(numbers.length - 1)
  }, [numbers.length])

  // By default input is valid
  if (typeof valid === 'undefined') {
    valid === true
  }
  return (
    <section className={styles.container} data-test-id={props.testName}>
      <label className={styles.label} htmlFor={`${idRef.current}-${lastInput}`}>
        {label && label.length > 0 ? (
          <p className={styles.labelText}>
            {required && <span className={styles.required}></span>}
            {label}
          </p>
        ) : null}
      </label>
      <ul className={styles.list}>
        {numbers.map((number, index) => (
          <li key={index} className={styles.listItem}>
            <div
              className={`${!valid && styles.invalid} ${
                styles.inputAndButtonWrapper
              }`.trim()}
            >
              <ReactPhoneInput
                country={'ru'}
                containerClass={`react-tel-input ${styles.inputContainer}`}
                inputClass={styles.input}
                buttonClass={styles.dropDownButton}
                buttonStyle={{
                  display: 'none',
                }}
                inputExtraProps={{
                  id: `${idRef.current}-${index}`,
                  required: required || false,
                }}
                disableDropdown
                enableAreaCodeStretch
                autoFormat={true}
                placeholder={'+7 (ххх) ххх-хх-хх'}
                value={number}
                onChange={(value:string, country:any, e:any, formattedValue:any) => {
                  // Set state with new array of numbers.
                  // New array is a copy of previous one with all indices kept
                  // only current value changed under current index.
                  // It is same as we set array[index] = newValue but instead
                  // mutating old array we get new one.
                  // Get parts of an array without current item

                  const [firsPart, lastPart] = [
                    // part before current item
                    numbers.slice(0, index),
                    // part after current item
                    numbers.slice(index + 1),
                  ]
                  setNumbers([...firsPart, value, ...lastPart])
                  setInOnChangeInput(true)
                  if (value.length === 1) e.target.selectionStart = e.target.value.length;
                }}
                disabled={disabled}
              />
            </div>
          </li>
        ))}
      </ul>
      {phoneCheck ? (
        <span data-test-id={props.testErrorName} className={styles.invalidText}>
          {accountForm.mobileWarningMessage[lang]}
        </span>
      ) : !valid && invalidText ? (
        <span data-test-id={props.testErrorName} className={styles.invalidText}>
          {invalidText}
        </span>
      ) : null}
    </section>
  )
}
