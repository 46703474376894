import React, { useState, useEffect, useRef } from 'react'

import { actions } from '../../state-actions-reducers'
import { Lang, ScreenProps, Country } from '../../types/common-types'
import { State, Dispatch } from '../../state-actions-reducers'
import styles from './SocialLinksInput.module.scss'
import TextInput from '../../components/Inputs/TextInput'
import SelectInput from '../../components/Inputs/SelectInput'
import DeleteButton from '../../components/Buttons/DeleteButton'
import AddButton from '../../components/Buttons/AddButton'
import { socialLinksOptions } from '../../content/socialLinks'

interface Props {
  lang: Lang
  state: State
  dispatch: Dispatch
  accountForm: any
  invalidFields: string[]
}
/* THE COMPONENT */
export default function SocialLinksInput({
  lang,
  state,
  dispatch,
  accountForm,
  invalidFields,
}: Props) {
  const [socialLinksCount, setSocialLinksCount] = useState(
    state.additionalLinksDescription.length || 1
  )
  const socialLinks = () => {
    let linksList = []
    for (let i = 0; i < socialLinksCount; i++) {
      linksList.push(
        <div
          className={styles.socialLinksRow + ' ' + 'row'}
          key={`socialLinksRow-${i}`}
        >
          <div className="col-3 col-xs-12">
            <SelectInput
              testName={`additionalLinksDescription-${i}`}
              testErrorName={`error-additionalLinksDescription-${i}`}
              noOptionsMessage={
                accountForm.additionalLinksDescription.placeholder
              }
              options={socialLinksOptions}
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.additionalLinksDescription}
              onChange={additionalLinksDescription => {
                dispatch({
                  type: actions.ADDITIONAL_LINKS_DESCRIPTION,
                  data:
                    i < state.additionalLinksDescription.length
                      ? state.additionalLinksDescription.map((item, key) => {
                          return key === i ? additionalLinksDescription : item
                        })
                      : state.additionalLinksDescription.concat(
                          additionalLinksDescription
                        ),
                })
              }}
              valid={!invalidFields.includes('additionalLinksDescription')}
              value={state.additionalLinksDescription[i]}
            />
          </div>
          <div className="col-4 col-xs-12">
            <TextInput
              testName={`additionalLinks-${i}`}
              testErrorName={`error-additionalLinks-${i}`}
              disabled={!state.agreed}
              lang={lang}
              content={accountForm.socialLink}
              onChange={additionalLink => {
                additionalLink = additionalLink.replace(
                  /[^A-Za-z0-9\<\>\@\!\#\$\%\^\&\*\(\)\_\+\[\]\{\}\?\:\;\|\'\"\,\.\/\~\`\\\-\=\'\ ]/gi,
                  ''
                )
                dispatch({
                  type: actions.ADDITIONAL_LINKS,
                  data:
                    i < state.additionalLinks.length
                      ? state.additionalLinks.map((item, key) => {
                          return key === i ? additionalLink : item
                        })
                      : state.additionalLinks.concat(additionalLink),
                })
              }}
              valid={!invalidFields.includes('additionalLinks')}
              value={state.additionalLinks[i]}
            />
            {i > 0 ? (
              <>
                <DeleteButton
                  testName="deleteSocialLink"
                  className={styles.socialLinksDeleteButton}
                  onClick={() => {
                    dispatch({
                      type: actions.ADDITIONAL_LINKS_DESCRIPTION,
                      data: state.additionalLinksDescription.filter(
                        (item, index) => index !== i
                      ),
                    })
                    dispatch({
                      type: actions.ADDITIONAL_LINKS,
                      data: state.additionalLinks.filter(
                        (item, index) => index !== i
                      ),
                    })
                    setSocialLinksCount(socialLinksCount - 1)
                  }}
                />
                <button
                  data-test-id="deleteSocialLink"
                  className={styles.socialLinksMobileDeleteButton}
                  onClick={() => {
                    dispatch({
                      type: actions.ADDITIONAL_LINKS_DESCRIPTION,
                      data: state.additionalLinksDescription.filter(
                        (item, index) => index !== i
                      ),
                    })
                    dispatch({
                      type: actions.ADDITIONAL_LINKS,
                      data: state.additionalLinks.filter(
                        (item, index) => index !== i
                      ),
                    })
                    setSocialLinksCount(socialLinksCount - 1)
                  }}
                >
                  Удалить
                </button>
              </>
            ) : null}
          </div>
        </div>
      )
    }
    return linksList
  }
  const [socialLinksList, setSocialLinksList] = useState(socialLinks)
  useEffect(() => {
    setSocialLinksList(socialLinks)
  }, [
    socialLinksCount,
    state.additionalLinksDescription,
    state.additionalLinks,
    invalidFields,
  ])
  return (
    <div className="form-group">
      <div className="row">
        <div className="col-12 col-xs-12">
          <h3>{accountForm.stepTwoSocialTitle[lang]}</h3>
        </div>
      </div>
      {socialLinksList}
      <div className="row">
        <div className="col-12 col-xs-12">
          <div
            data-test-id="addSocialLink"
            className={styles.addButtonBox}
            onClick={() => {
              console.log(socialLinksCount)
              setSocialLinksCount(socialLinksCount + 1)
              dispatch({
                type: actions.ADDITIONAL_LINKS_DESCRIPTION,
                data: state.additionalLinksDescription.concat(''),
              })
              dispatch({
                type: actions.ADDITIONAL_LINKS,
                data: state.additionalLinks.concat(''),
              })
            }}
          >
            <AddButton />
            <span>{accountForm.socialLinksButton[lang]}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
