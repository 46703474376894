export const getPassportTypeTranslate = (type: number) => {
  switch (type) {
    case 0: {
      return 'не выбрано'
    }
    case 1: {
      return 'Паспорт'
    }
    case 2: {
      return 'Уд. личности'
    }
    case 3: {
      return 'Загран. паспорт'
    }
    case 4: {
      return 'Вид на жительство'
    }
    case 8: {
      return 'ID карта'
    }
    case 11: {
      return 'РВП'
    }
  }
}
