export const familyStatusMaleOptions = [
    {
      value: '0',
      label: {
        eng: 'Prefer not to say',
        rus: 'Не выбрано',
        kz: 'Таңдалмаған',
      }
    },
    {
      value: '1',
      label: {
          eng: 'Single',
          rus: 'Не женат',
          kz: 'Үйленген жоқ',
      }
    },
    {
      value: '2',
      label: {
          eng: 'Мarried',
          rus: 'Женат',
          kz: 'Үйленген',
      }
    },
  ]
  export const familyStatusFemaleOptions = [
    {
      value: '0',
      label: {
        eng: 'Prefer not to say',
        rus: 'Не выбрано',
        kz: 'Таңдалмаған',
      }
    },
    {
      value: '1',
      label: {
          eng: 'Single',
          rus: 'Не замужем',
          kz: 'Үйленген жоқ',
      }
    },
    {
      value: '2',
      label: {
          eng: 'Мarried',
          rus: 'Замужем',
          kz: 'Үйленген',
      }
    },
  ]