import { useEffect } from 'react'
import { request } from '../utils/helpers'

import { LOCAL_STORAGE_KEYS } from '../constant'
import { API_URL, TOKEN_ENDPOINT } from '../server-parameters'
import { ObjectValueNode } from 'graphql'
import { State } from '@uppy/core'
import { getUserAndFormId } from './useTokenCustomHook'

// const headers = new Headers({
// 	'content-type': 'application/json',
// 	accept: 'text/plain'
// })

export async function useOrderSubmit(
  form: State,
  startDate: any,
  endDate: any,
  goods: any
) {
  const headers = new Headers({
    'content-type': 'application/json',
    accept: 'text/plain',
  })
  if (!headers.has('authorization')) {
    const token = form.orderId
    headers.set('Authorization', 'Bearer ' + token)
  }

  const fullUrl = API_URL + '/Orders '

  const formData = {
    start: startDate,
    end: endDate,
    goods: goods,
    user: {
      lastName: form.lastName,
      firstName: form.firstName,
      patronymic: form.patronymic,
      phone: form.mobilePhoneNumbers[0],
      email: form.emails[0],
    },
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: '',
  }
  const fetchOptions = {
    headers,
    body: JSON.stringify(formData),
    method: 'POST',
  }
  let response = await fetch(fullUrl, fetchOptions)
  if (
    response.status === 400 ||
    response.status === 401 ||
    response.status === 403
  ) {
    getUserAndFormId(() => {}, window.localStorage)
  }
  const body = await response.json()
  //console.log(body)
  return body
}
